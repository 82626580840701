import { useEffect, useState } from 'react';
import { BREAKPOINT } from '../constant';

const useBreakpoints = () => {
  const [width, setWidth] = useState(() => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    return 0;
  });

  const isMobile = width <= BREAKPOINT.mobile;
  const isTablet = width <= BREAKPOINT.table;
  const isDesktop = width <= BREAKPOINT.desktop;

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);
  return { width, isMobile, isTablet, isDesktop };
};

export default useBreakpoints;
