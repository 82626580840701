/* eslint-disable no-undef */
import { MAINNET_ADDRESS } from './mainnet';
import { TESTNET_ADDRESS } from './testnet';

const contractAddress = () => {
  const chainName = process.env.REACT_APP_PRODUCT_ENV;
  if (chainName === 'testnet') return TESTNET_ADDRESS;
  return MAINNET_ADDRESS;
};

const CONTRACT_ADDRESS = contractAddress();

export default CONTRACT_ADDRESS;
