import { ReactNode, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import AffiliateIcon from '../Icons/AffiliateIcon';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import BaccaratIcon from '../Icons/BaccaratIcon';
import BlackJackIcon from '../Icons/BlackJackIcon';
import ClassicDiceIcon from '../Icons/ClassicDiceIcon';
import CrashIcon from '../Icons/CrashIcon';
import CrazyTimeIcon from '../Icons/CrazyTimeIcon';
import HomeIcon from '../Icons/HomeIcon';
import LotteryIcon from '../Icons/LotteryIcon';
import MegaBallIcon from '../Icons/MegaBallIcon';
import RingOfFortuneIcon from '../Icons/RingOfFortuneIcon';
import RouteLetteIcon from '../Icons/RouteLetteIcon';
import StakingIcon from '../Icons/StakingIcon';
import Collapse from '../UI/Collapse';
import Divider from '../UI/Divider';
import styles from '../../styles/Layout/sidebar.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

export default function Sidebar() {
  const [isCollapse, setIsCollapse] = useState(false);

  const list1 = [
    { icon: <HomeIcon />, title: 'Home', link: ROUTES.HOME },
    { icon: <CrashIcon />, title: 'Crash', link: ROUTES.CRASH },
    { icon: <RingOfFortuneIcon />, title: 'Ring of Fortune', link: ROUTES.RING_OF_FORTUNE },
    { icon: <ClassicDiceIcon />, title: 'Classic Dice', link: ROUTES.CLASSIC_DICE }
  ];
  const list2 = [
    // { icon: <LotteryIcon />, title: 'Lottery', link: ROUTES.LOTTERY },
    // { icon: <AffiliateIcon />, title: 'Affiliate', link: ROUTES.AFFILIATE },
    { icon: <StakingIcon />, title: 'Staking', link: ROUTES.STAKING }
  ];
  const list3 = [
    { icon: <BaccaratIcon />, title: 'Lottery', link: ROUTES.BACCARAT },
    { icon: <RouteLetteIcon />, title: 'Affiliate', link: ROUTES.ROULETTE },
    { icon: <BlackJackIcon />, title: 'Blackjack', link: ROUTES.BLACKJACK },
    { icon: <CrazyTimeIcon />, title: 'Crazy Time', link: ROUTES.CRAZY_TIME },
    { icon: <MegaBallIcon />, title: 'Mega Ball', link: ROUTES.MEGA_BALL }
  ];

  return (
    <div className={cx('sidebar-root')}>
      <div className={cx('logo-container')}>
        <Link to={ROUTES.HOME}>
          <img src="/images/logo.svg" alt="logo" />
        </Link>
      </div>
      <div>
        {/* {list1.map((item) => (
          <MenuItem key={item.title} {...item} />
        ))} */}

        <div className={cx('menu-divider')}>
          <Divider />
        </div>

        {list2.map((item) => (
          <MenuItem key={item.title} {...item} />
        ))}

        {/* <div className={cx('menu-divider')}>
          <Divider />
        </div> */}

        {/* <div
          className={cx('menu-collapse')}
          onClick={() => {
            setIsCollapse((prevIsCollapse) => !prevIsCollapse);
          }}>
          <p>Live Casino</p>
          <ArrowDownIcon />
        </div> */}

        {/* <Collapse collapsed={isCollapse}>
          {list3.map((item) => (
            <MenuItem key={item.title} {...item} />
          ))}
        </Collapse> */}
      </div>
    </div>
  );
}

interface MenuItemPropsType {
  link: string;
  title: string;
  icon: ReactNode;
}

const MenuItem = ({ link, title, icon }: MenuItemPropsType) => {
  return (
    <NavLink
      end
      to={link}
      key={title}
      className={({ isActive }) => cx('nav-link', isActive && 'activated')}>
      <div className={cx('menu-item-container')}>
        {icon}
        <p className={cx('menu-item-title')}>{title}</p>
      </div>
    </NavLink>
  );
};
