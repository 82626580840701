export const ROUTES = {
  ERROR: '*',
  HOME: '/',
  CRASH: '/crash',
  RING_OF_FORTUNE: '/ring-of-fortune',
  CLASSIC_DICE: '/classic-dice',
  LOTTERY: '/lottery',
  AFFILIATE: '/affiliate',
  STAKING: '/staking',
  BACCARAT: '/baccarat',
  ROULETTE: '/roulette',
  BLACKJACK: '/black-jack',
  CRAZY_TIME: '/crazy-time',
  MEGA_BALL: '/mega-ball',
  LOGIN: '/login',
  WALLET: {
    MAIN: '/wallet',
    DEPOSIT: '/wallet/deposit',
    WITHDRAW: '/wallet/withdraw',
    TRANSACTIONS: '/wallet/transactions'
  }
};
