import classNames from 'classnames/bind';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import styles from '../../styles/UI/modal.module.scss';
import ClosedIcon from '../Icons/ClosedIcon';

const cx = classNames.bind(styles);

interface PropsType {
  isShowing: boolean;
  children: React.ReactNode;
  hide: () => void;
  title?: string | React.ReactNode;
  backgroundHeader?: string;
  footer?: React.ReactNode;
}

const ModalUI = ({ isShowing, children, hide, title, backgroundHeader, footer }: PropsType) => {
  useEffect(() => {
    if (isShowing) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = 'visible';
  }, [isShowing]);
  return isShowing
    ? ReactDOM.createPortal(
        <>
          <div className={cx('modal-container')}>
            <div className={cx('background')} onClick={hide} />
            <div className={cx('modal')}>
              <div className={cx('modal-header')} style={{ background: backgroundHeader }}>
                <div className={cx('title')}>{title}</div>
                <button type="button" className={cx('modal-close-button')} onClick={hide}>
                  <ClosedIcon />
                </button>
              </div>
              <div className={cx('modal-content')}>{children}</div>
              {footer && <div className={cx('modal-footer')}>{footer}</div>}
            </div>
          </div>
        </>,
        document.body
      )
    : null;
};

export default ModalUI;
