import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Account } from '../interfaces/Account';
import { firebaseService } from '../services/firebase';
import { storageService } from '../services/storage';
export interface AccountState {
  accountInfo: Account;
  isLoggedIn: boolean;
  currency: string;
}

const initialState: AccountState = {
  accountInfo: {
    accessToken: '',
    displayName: '',
    email: '',
    photoURL: '',
    id: ''
  },
  isLoggedIn: false,
  currency: 'BBET'
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccountInfo: (state, action: PayloadAction<Account>) => {
      state.accountInfo = action.payload;
    },
    setLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
    },
    logout: (state) => {
      state.accountInfo = {
        accessToken: '',
        displayName: '',
        email: '',
        photoURL: '',
        id: ''
      };
      state.isLoggedIn = false;

      firebaseService.logout();
      storageService.saveExpiredTimeAccessToken('', -1);
      storageService.removeAccount();
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAccountInfo, setLoggedIn, logout } = accountSlice.actions;

export default accountSlice.reducer;
