import classNames from 'classnames/bind';
import styles from '../../styles/components/home/banner.module.scss';
import FireIcon from '../Icons/FireIcon';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';

const cx = classNames.bind(styles);

const ItemBanner = () => (
  <div className={cx('wrapper')}>
    <div className={cx('content')}>
      <div className={cx('title')}>Classic Dice</div>
      <div className={cx('desc')}>Catch the winning spirit</div>
      <div className={cx('house-edge')}>
        <div className={cx('icon')}>
          <FireIcon />
        </div>
        <div>House Edge: 1,00%</div>
      </div>
    </div>

    <div className={cx('action')}>
      <button className={cx('button-play')}>Play now</button>
    </div>
  </div>
);

const Banner = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (_: any, className: any) {
      return `<div class="${className}"></div>`;
    }
  };

  return (
    <div className={cx('slide', 'slide-custom')}>
      <Swiper
        autoplay={{
          delay: 3000,
          disableOnInteraction: false
        }}
        pagination={pagination}
        modules={[Autoplay, Pagination]}
        className={cx('swiper-slide')}
        spaceBetween={50}
        slidesPerView={1}
        loop={true}>
        <SwiperSlide>
          <ItemBanner />
        </SwiperSlide>
        <SwiperSlide>
          <ItemBanner />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Banner;
