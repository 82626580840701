import styles from '../../styles/Layout/footer.module.scss';
import classnames from 'classnames/bind';
import HelpIcon from '../Icons/HelpIcon';
import BusinessIcon from '../Icons/BusinessIcon';
import TelegramIcon from '../Icons/TelegramIcon';
import TwitterIcon from '../Icons/TwitterIcon';
import MediumIcon from '../Icons/MediumIcon';

const cx = classnames.bind(styles);

export default function Footer() {
  const networks = [
    { key: 'tele', icon: <TelegramIcon />, link: 'https://t.me/ByBetChat' },
    {
      key: 'twitter',
      icon: <TwitterIcon />,
      link: 'https://twitter.com/ByBetOfficial'
    },
    // { key: "discord", icon: <DiscordIcon />, link: "" },
    // { key: "reddit", icon: <RedditIcon />, link: "" },
    {
      key: 'medium',
      icon: <MediumIcon />,
      link: 'https://medium.com/@bybetofficial'
    }
  ];

  return (
    <div className={cx('footer-root')}>
      <div className={cx('footer-container')}>
        <div className={cx('contact-us')}>
          <p>CONTACT US</p>
          <div className={cx('footer-row')}>
            <HelpIcon />{' '}
            <p className={cx('contact-us-text')}>
              Support 24/7:{' '}
              <a className={cx('footer-link')} target={'_blank'} rel="noreferrer">
                support@bybet.io
              </a>
            </p>
          </div>
          <div className={cx('footer-row')}>
            <BusinessIcon />{' '}
            <p className={cx('contact-us-text')}>
              Info:{' '}
              <a className={cx('footer-link')} target={'_blank'} rel="noreferrer">
                info@bybet.io
              </a>
            </p>
          </div>
        </div>
        <div className={cx('accepted-currencies')}>
          <p>ACCEPTED CURRENCIES</p>
        </div>
        <div className={cx('social-networks-container')}>
          <div className={cx('footer-row')}>
            {networks.map((network) => (
              <a key={network.key} href={network.link} target={'_blank'} rel="noreferrer">
                {network.icon}
              </a>
            ))}
          </div>
          <p>Copyright © 2022 BYBET All Right Reserved</p>
        </div>
      </div>
    </div>
  );
}
