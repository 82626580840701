export default function TelegramIcon() {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.0513 15.1664L9.39896 16.7889L11.0818 22.2006C11.1896 22.5475 11.6132 22.6752 11.8945 22.4455L14.3179 20.4697C14.5722 20.2629 14.9337 20.2524 15.1991 20.4451L19.5702 23.6186C19.8714 23.8371 20.2974 23.6725 20.373 23.3086L23.5751 7.90606C23.6577 7.5088 23.2669 7.17716 22.889 7.32364L5.04661 14.2078C4.60599 14.3772 4.61009 15.0006 5.0513 15.1664ZM10.8105 15.9252L19.3072 10.6922C19.4601 10.5984 19.6171 10.8047 19.4859 10.9266L12.474 17.4445C12.2273 17.6742 12.0685 17.9813 12.0234 18.3141L11.7843 20.0842C11.7527 20.3203 11.4204 20.3438 11.3554 20.1152L10.4366 16.8873C10.3318 16.5193 10.4853 16.1262 10.8105 15.9252Z"
        fill="white"
      />
    </svg>
  );
}
