import React from 'react';
import styles from '../../styles/UI/card.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface PropsType {
  children: React.ReactNode;
}

export function Card({ children }: PropsType) {
  // const subComponentList = Object.keys(Card);

  // const subComponents = subComponentList.map((key) => {
  //   return (
  //     <React.Fragment key={key}>
  //       {React.Children.map(children, (child) => {
  //         console.log(child.type.name);
  //         return child.type.name === key ? child : null;
  //       })}
  //     </React.Fragment>
  //   );
  // });

  return (
    <div className={cx('root')}>
      <div className={cx('container')}>
        <div className={cx('padding')}>
          {/* {subComponents.map((component) => component)} */}
          {children}
        </div>
      </div>
    </div>
  );
}

export const CardHeader = ({ children }: PropsType) => (
  <div className={cx('header')}>{children}</div>
);
// Card.Header = Header;

export const CardBody = ({ children }: PropsType) => <div className={cx('body')}>{children}</div>;
// Card.Body = Body;
