export const convertToCurrency = (value: string | number | undefined | null) => {
  let valueNumber = parseFloat(value + '');

  if (!isNaN(valueNumber)) {
    return new Intl.NumberFormat('en-US', {
      currency: 'USD',
      style: 'decimal'
    }).format(valueNumber);
  }
  return 0;
};
