export default function TwitterIcon() {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9469 23.8823C12.095 23.8823 11.2178 23.8131 10.336 23.6748C8.78208 23.4311 7.20942 22.6184 6.76411 22.3752L5.59692 21.7371L6.86079 21.3217C8.24243 20.8676 9.08267 20.5858 10.1227 20.1446C9.08091 19.6401 8.27817 18.7325 7.89204 17.56L7.5979 16.6676L7.83872 16.7045C7.61021 16.4742 7.42974 16.2405 7.2897 16.0278C6.79224 15.2719 6.52856 14.3485 6.58481 13.5569L6.63989 12.7776L7.1063 12.958C6.90942 12.5866 6.76763 12.1899 6.68384 11.7739C6.47993 10.7596 6.65044 9.68206 7.16372 8.73987L7.56978 7.99398L8.11294 8.64671C9.83149 10.711 12.0083 11.9356 14.5911 12.2942C14.4856 11.567 14.5647 10.8651 14.826 10.2358C15.1307 9.50335 15.6727 8.88167 16.3928 8.43929C17.1926 7.94769 18.1629 7.70921 19.1245 7.76839C20.1446 7.83109 21.0709 8.21312 21.8063 8.87464C22.1655 8.78148 22.4303 8.68187 22.7877 8.5471C23.0028 8.46624 23.2471 8.37425 23.5524 8.26878L24.678 7.88089L23.9438 9.97679C23.9924 9.97269 24.0422 9.96917 24.0944 9.96683L25.2961 9.91234L24.586 10.8832C24.545 10.9389 24.535 10.9547 24.5204 10.9764C24.4629 11.0625 24.392 11.1698 23.417 12.4717C23.1727 12.7975 23.0508 13.2223 23.0737 13.6676C23.1604 15.3592 22.9524 16.8903 22.4561 18.2168C21.9868 19.4719 21.2596 20.5494 20.2952 21.4184C19.1016 22.4936 17.5793 23.2295 15.7706 23.6057C14.8858 23.7891 13.9331 23.8823 12.9469 23.8823Z"
        fill="white"
      />
    </svg>
  );
}
