import { ReactNode } from 'react';
import ComingSoon from '../components/ComingSoon';
import Deposit from '../components/Wallet/Deposit';
import Transactions from '../components/Wallet/Transactions';
import Withdraw from '../components/Wallet/Withdraw';
import { ROUTES } from '../constant/routes';
import ErrorPage from '../pages/error';
import HomePage from '../pages/home';
import RingOfFortunePage from '../pages/ring-of-fortune';
import StakingPage from '../pages/staking';
import WalletPage from '../pages/wallet';

interface Route {
  path: string;
  element: ReactNode;
}

const { WALLET } = ROUTES;

export const routes = [
  { path: ROUTES.ERROR, element: <ErrorPage /> },
  // _______
  { path: ROUTES.HOME, element: <HomePage /> },
  // { path: ROUTES.RING_OF_FORTUNE, element: <RingOfFortunePage /> },
  // { path: ROUTES.HOME, element: <ComingSoon /> },
  { path: ROUTES.RING_OF_FORTUNE, element: <ComingSoon /> },
  { path: ROUTES.CRASH, element: <ComingSoon /> },
  { path: ROUTES.CLASSIC_DICE, element: <ComingSoon /> },

  // ROUTES WALLET
  // { path: WALLET.MAIN, element: <WalletPage /> },
  // { path: WALLET.DEPOSIT, element: <Deposit /> },
  // { path: WALLET.WITHDRAW, element: <Withdraw /> },
  // { path: WALLET.TRANSACTIONS, element: <Transactions /> },
  { path: WALLET.MAIN, element: <ComingSoon /> },
  { path: WALLET.DEPOSIT, element: <ComingSoon /> },
  { path: WALLET.WITHDRAW, element: <ComingSoon /> },
  { path: WALLET.TRANSACTIONS, element: <ComingSoon /> },

  // -------
  { path: ROUTES.LOTTERY, element: <ComingSoon /> },
  { path: ROUTES.AFFILIATE, element: <ComingSoon /> },
  { path: ROUTES.STAKING, element: <StakingPage /> }
] as Route[];
