import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Loading } from '../interfaces/Loading';

export interface LoadingState {
  loading: Loading;
}

const initialState: LoadingState = {
  loading: {
    global: false,
    staking: false
  }
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    updateLoadingGlobal: (state, action: PayloadAction<boolean>) => {
      state.loading.global = action.payload;
    },
    updateLoadingStaking: (state, action: PayloadAction<boolean>) => {
      state.loading.staking = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateLoadingGlobal, updateLoadingStaking } = loadingSlice.actions;

export default loadingSlice.reducer;
