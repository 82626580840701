import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ClaimRewards from '../components/Staking/ClaimRewards';
import History from '../components/Staking/History';
import RewardTracker from '../components/Staking/RewardTracker';
import StakeBBet from '../components/Staking/StakeBBet';
import { Card, CardHeader } from '../components/UI/Card';
import { TOKENS } from '../constant/token';
import CONTRACT_ADDRESS from '../contracts/address';
import { useStakeBBet } from '../hooks/useConstract';
import { RootState } from '../stores';
import styles from '../styles/staking.module.scss';

const cx = classnames.bind(styles);

export default function StakingPage() {
  return (
    <div className={cx('staking-root')}>
      <StatisticBlock />

      <div className={cx('reward-tracker')}>
        <RewardTracker />
      </div>
      <div className={cx('stake-bbet')}>
        <StakeBBet />
      </div>
      <div className={cx('reward-tracker')}>
        <ClaimRewards />
      </div>

      <div className={cx('history')}>
        <History />
      </div>
    </div>
  );
}

const StatisticBlock = () => {
  const [tvl, setTvl] = useState('0');
  const [totalXbbet, setTotalXbbet] = useState('0');
  const [asp, setAsp] = useState('0');
  const stakeBBetContract = useStakeBBet(CONTRACT_ADDRESS.STAKING);
  const loadingStaking = useSelector((state: RootState) => state.loading.loading.staking);

  const getData = async () => {
    try {
      const [tvl, totalXBBET, tlt, stakingUsers] = await Promise.all([
        stakeBBetContract.totalValuedLock(),
        stakeBBetContract.totalX(),
        stakeBBetContract.totalLockTime(),
        stakeBBetContract.numberOfStakingUsers()
      ]);

      setTvl(new BigNumber(tvl.toString()).div(1e18).toFormat(2));
      setTotalXbbet(new BigNumber(totalXBBET.toString()).div(1e18).toFormat(2));
      const asp = new BigNumber(stakingUsers.toString()).gt(0)
        ? new BigNumber(tlt.toString())
            .div(60 * 60 * 24)
            .div(stakingUsers.toString())
            .toFormat(2)
        : new BigNumber(0).toFormat(2);

      setAsp(asp);
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!loadingStaking) {
      getData();
    }
  }, [loadingStaking]);

  return (
    <>
      <StatisticCard title={`${TOKENS.BBET} TVL`} value={tvl} />
      <StatisticCard title={`${TOKENS.XBT} in circulation`} value={totalXbbet} />
      <StatisticCard title={'Average Staking Period'} value={`${asp} Day`} />
    </>
  );
};

interface PropsCardType {
  title: string;
  value: string;
}

const StatisticCard = ({ title, value }: PropsCardType) => {
  return (
    <div className={cx('statistic-card')}>
      <Card>
        <CardHeader>
          <p className={cx('statistic-title')}>{title}</p>
          <p className={cx('statistic-value')}>{value}</p>
        </CardHeader>
      </Card>
    </div>
  );
};
