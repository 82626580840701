import React, { useEffect, useState } from 'react';
import styles from '../../styles/Staking/reward-tracker.module.scss';
import classnames from 'classnames/bind';
import { useStakeBBet } from '../../hooks/useConstract';
import CONTRACT_ADDRESS from '../../contracts/address';
import BigNumber from 'bignumber.js';
import { RootState } from '../../stores';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from '../UI/Card';
import { TOKENS } from '../../constant/token';
import moment from 'moment';

const cx = classnames.bind(styles);

export default function RewardTracker() {
  const [todayRewards, setTodayRewards] = useState('0');
  const [yesterdayRewards, setYesterdayRewards] = useState('0');
  const [dailyRewards, setDailyRewards] = useState('0');
  const [annualRewardsPerBBet, setAnnualRewardsPerBBet] = useState('0');
  const stakeBBetContract = useStakeBBet(CONTRACT_ADDRESS.STAKING);
  const loadingStaking = useSelector((state: RootState) => state.loading.loading.staking);

  const getData = async () => {
    try {
      const [todayRewards, yesterdayRewards, totalRewards, startStakingAt, tvl] = await Promise.all(
        [
          stakeBBetContract.todayReward(),
          stakeBBetContract.yesterdayReward(),
          stakeBBetContract.totalReward(),
          stakeBBetContract.startStakingAt(),
          stakeBBetContract.totalValuedLock()
        ]
      );
      const totalTime = new BigNumber(moment().valueOf())
        .div(1000)
        .minus(new BigNumber(startStakingAt.toString()))
        .toFixed();
      setTodayRewards(new BigNumber(todayRewards.toString()).div(1e18).toFormat(2));
      setYesterdayRewards(new BigNumber(yesterdayRewards.toString()).div(1e18).toFormat(2));
      const totalTimeDaily = new BigNumber(totalTime.toString()).div(60 * 60 * 24).toString();
      const totalTimeAnnual = new BigNumber(totalTime.toString()).div(60 * 60 * 24 * 365).gte(1)
        ? new BigNumber(totalTime.toString()).div(60 * 60 * 24 * 365).toString()
        : 1;

      if (new BigNumber(totalTime.toString()).lte(0)) {
        setDailyRewards('0.00');
        setAnnualRewardsPerBBet('0.00');
      } else {
        setDailyRewards(
          new BigNumber(totalRewards.toString()).div(1e18).div(totalTimeDaily).toFormat(2)
        );
        if (new BigNumber(tvl.toString()).gt(0)) {
          setAnnualRewardsPerBBet(
            new BigNumber(totalRewards.toString())
              .div(totalTimeAnnual)
              .div(tvl.toString())
              .toFormat(2)
          );
        } else {
          setAnnualRewardsPerBBet('0.00');
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!loadingStaking) {
      getData();
    }
  }, [loadingStaking]);

  return (
    <Card>
      <CardHeader>
        <div className={cx('header')}>
          <p className={cx('header-title')}>Reward Tracker</p>
          <img src={`/images/tokens/${TOKENS.BUSD.toLowerCase()}-large.svg`} alt={TOKENS.BUSD} />
        </div>
      </CardHeader>
      <CardBody>
        <div className={cx('body-item')}>
          <p className={cx('body-item-key')}>Today’s staking rewards</p>
          <p>{todayRewards}</p>
        </div>
        <div className={cx('body-item')}>
          <p className={cx('body-item-key')}>Yesterday’s staking rewards</p>
          <p>{yesterdayRewards}</p>
        </div>
        <div className={cx('body-item')}>
          <p className={cx('body-item-key')}>Average daily staking rewards</p>
          <p>{dailyRewards}</p>
        </div>
        <div className={cx('body-item')}>
          <p className={cx('body-item-key')}>Average annual staking rewards per BBET</p>
          <p>{annualRewardsPerBBet}</p>
        </div>
      </CardBody>
    </Card>
  );
}
