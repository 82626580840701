import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ModalState {
  [key: string]: boolean;
}

const initialState: ModalState = {
  userProfileModal: false,
  editNicknameModal: false,
  editAvatarModal: false,
  statisticDetailModal: false,
  security2FAModal: false
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleModal: (state, action: PayloadAction<{ modalName: string; state: boolean }>) => {
      state[action.payload.modalName] = action.payload.state;
    }
  }
});

// Action creators are generated for each case reducer function
export const { toggleModal } = modalSlice.actions;

export default modalSlice.reducer;
