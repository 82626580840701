export default function ArrowRightIcon() {
  return (
    <svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 5.9585L12.5 10.9585L7.5 15.9585"
        stroke="#A2A3A4"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
