import classnames from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useModal from '../../hooks/useModal';
import { useAppSelector } from '../../stores';
import styles from '../../styles/Layout/header.module.scss';
import ArrowDownIcon from '../Icons/ArrowDownIcon';
import CoinIcon from '../Icons/CoinIcon';
import MessageIcon from '../Icons/MessageIcon';
import NotificationIcon from '../Icons/NotificationIcon';
import SearchIcon from '../Icons/SearchIcon';
import WalletIcon from '../Icons/WalletIcon';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constant/routes';
import ModalAuth from '../Modal/ModalAuth';
import Dropdown from '../UI/Dropdown';
import ModalUserInformation from '../Modal/ModalUserInformation';
import { toggleModal } from '../../stores/ModalSlice';
import useUserIdentity from '../../hooks/useUserIdentity';
import ModalSecurity2FA from '../Modal/ModalSecurity2FA';
import ProfileAccount from './ProfileAccount';

const cx = classnames.bind(styles);

interface PropsType {
  handleShowChat: () => void;
}

interface User {
  id: string;
  identifier: string;
  email: string | null;
  nickName: string | null;
  status: string;
  firstName: string | null;
  lastName: string;
  walletAddress: string | null;
  birthday: number;
  isAdmin: boolean;
  signedInAt: number;
  signedUpAt: number;
  avatar: string | null;
  state: string;
  address: string | null;
  verifiedAt: number;
  createdAt: number;
  updatedAt: number;
  isEnableTwoFactor: boolean;
  role: any[];
}

const Header = ({ handleShowChat }: PropsType) => {
  const { account } = useAppSelector((state) => ({
    account: state.account.accountInfo
  }));

  const { isShowing, toggle } = useModal();
  const userIdentitySDK = useUserIdentity();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [typeModalAuth, setTypeModalAuth] = useState<string>('');
  const [slideStart, setSlideStart] = useState<number>(NaN);
  const [user, setUser] = useState<User>();

  const handleTypeModalAuth = (type: string) => {
    setTypeModalAuth(type);
  };

  const onClickButtonAuth = (type: string) => {
    handleTypeModalAuth(type);
    toggle();
  };

  const fetchUserProfile = async (id: string) => {
    try {
      const res = await userIdentitySDK.getUser(id);
      setUser(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleReload = () => {
    account.id && fetchUserProfile(account.id);
  };

  useEffect(() => {
    account.id && fetchUserProfile(account.id);
  }, [account]);

  return (
    <div className={cx('header-root')}>
      {/* <div className={cx('header-logo')}>
        <img src="/images/logo.svg" alt="logo" />
      </div>
      <InputSearch />
      {isLoggedIn ? (
        <div className={cx('header-group-right')}>
          <div className={cx('wallet')}>
            <div className={cx('wallet-desc')}>
              <div className={cx('coin-name')}>
                <div>
                  <CoinIcon />
                </div>
                <div className={cx('name')}>{currency}</div>
                <div>{<ArrowDownIcon />}</div>
              </div>
              <div className={cx('total-coin')}>108,256.025</div>
            </div>
            <button
              onClick={() => navigate(ROUTES.WALLET.MAIN)}
              type="button"
              className={cx('button', 'button-primary')}>
              <div className={cx('button-wallet')}>
                <div className={cx('icon')}>
                  <WalletIcon />
                </div>
                <div>Wallet</div>
              </div>
            </button>
          </div>
          <div className={cx('header-group-right-item')} onClick={handleShowChat}>
            <div className={cx('message')}>
              <MessageIcon />
            </div>
          </div>
          <div className={cx('header-group-right-item')}>
            <NotificationIcon />
          </div>
          <div className={cx('header-group-right-item')}>
            <Dropdown
              placement="right"
              overlay={<ProfileAccount user={user} setSlideStart={setSlideStart} />}>
              <img
                src={user?.avatar || account.photoURL || '/images/avatar.png'}
                alt="avatar"
                className={cx('header-avatar')}
              />
            </Dropdown>
          </div>
        </div>
      ) : (
        <div className={cx('button-group')}>
          <button
            onClick={() => onClickButtonAuth('sign_in')}
            type="button"
            className={cx('button', 'button-outline')}>
            Sign in
          </button>
          <button
            onClick={() => onClickButtonAuth('sign_up')}
            type="button"
            className={cx('button', 'button-primary')}>
            Sign up
          </button>
          <div className={cx('message')} onClick={handleShowChat}>
            <MessageIcon />
          </div>
          <ModalAuth
            handleTypeModalAuth={handleTypeModalAuth}
            isShowing={isShowing}
            toggle={toggle}
            type={typeModalAuth}
          />
        </div>
      )}
      <ModalUserInformation
        user={{
          name: user?.nickName || account.displayName,
          userId: user?.id || '',
          avatar: user?.avatar || account.photoURL || '/images/avatar.png',
          totalWins: 0,
          totalBets: 0,
          totalWagered: 0,
          favoriteGames: [],
          wagerContest: []
        }}
        slideStart={slideStart}
        isShowing={userProfileModal}
        toggle={() =>
          dispatch(toggleModal({ modalName: 'userProfileModal', state: !userProfileModal }))
        }
        reload={handleReload}
      />
      <ModalSecurity2FA
        isShowing={security2FAModal}
        toggle={() => {
          dispatch(toggleModal({ modalName: 'security2FAModal', state: !security2FAModal }));
        }}
      /> */}
    </div>
  );
};

export default Header;

const InputSearch = () => {
  return (
    <div className={cx('header-input-search-container')}>
      <input placeholder="Search..." className={cx('header-input-search')} />
      <SearchIcon />
    </div>
  );
};
