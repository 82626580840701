import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../../styles/components/home/index.module.scss';
import Banner from './Banner';
import TopWining from './TopWining';
import Tabs from './Tabs';
import ListGame from './ListGame';
import { TAB_HOME } from '../../constant/data-home-page';

const cx = classNames.bind(styles);

export default function Home() {
  const [tabActive, setTabActive] = useState<string>(TAB_HOME.LOPPY);
  return (
    <div>
      <div className={cx('header')}>
        <div className={cx('banner')}>
          <Banner />
        </div>
        <div className={cx('top-wining')}>
          <TopWining />
        </div>
      </div>
      <div className={cx('tabs')}>
        <Tabs setTabActive={setTabActive} tabActive={tabActive} />
      </div>
      <div>
        <ListGame />
      </div>
    </div>
  );
}
