import styles from '../../styles/UI/radio-button.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

interface PropsType {
  label: string;
  checked?: boolean;
  handleChange?: any;
}

export default function RadioButton({ label, checked = false, handleChange }: PropsType) {
  return (
    <label className={cx('container')}>
      {label}
      <input type="radio" checked={checked} name={label} onChange={handleChange} />
      <span className={cx('checkmark')} />
    </label>
  );
}
