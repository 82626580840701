import React from 'react';

interface PropsType {
  active?: boolean;
}

const OriginalIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_566_835)">
        <path
          d="M17.4482 21.3146L23.7188 10.4324C24.3374 9.53256 23.8875 8.25312 22.9877 7.74697L11.8805 1.32166C11.051 0.745211 9.61687 1.12482 9.18102 2.03871L7.18454 5.49741H14.8471C16.2812 5.49741 17.4622 6.66437 17.4622 8.09846C17.4622 12.2883 17.7012 17.2232 17.4482 21.3146ZM4.56942 8.77333C5.65202 8.77333 6.52373 9.64504 6.52373 10.7276C6.52373 11.8102 5.65202 12.6819 4.56942 12.6819C3.50088 12.6819 2.62917 11.8102 2.62917 10.7276C2.62917 9.64504 3.50088 8.77333 4.56942 8.77333ZM14.9315 22.9456C16.1265 22.9456 16.8014 22.0036 16.8014 20.8928V19.5572V8.09846C16.8014 7.02992 15.9297 6.14416 14.8471 6.14416C9.16696 6.14416 6.95958 6.14416 1.95431 6.14416C0.871705 6.14416 0 7.02992 0 8.09846V21.0053C0 22.0739 0.871705 22.9456 1.95431 22.9456H14.9315ZM8.39367 12.5976C9.47627 12.5976 10.348 13.4693 10.348 14.5519C10.348 15.6204 9.47627 16.5062 8.39367 16.5062C7.32513 16.5062 6.45343 15.6204 6.45343 14.5519C6.45343 13.4693 7.32513 12.5976 8.39367 12.5976ZM12.2179 16.4218C13.3005 16.4218 14.1722 17.2935 14.1722 18.3761C14.1722 19.4447 13.3005 20.3305 12.2179 20.3305C11.1494 20.3305 10.2777 19.4447 10.2777 18.3761C10.2777 17.2935 11.1494 16.4218 12.2179 16.4218Z"
          fill={active ? '#FFE036' : '#7F7F7F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_566_835">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OriginalIcon;
