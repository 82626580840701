import styles from '../../styles/loading.module.scss';
import classnames from 'classnames/bind';

const cx = classnames.bind(styles);

export default function Loading() {
  return (
    <div className={cx('root')}>
      <img src="/images/loading.gif" alt="loading" className={cx('img')} />
      <p>Please wait...</p>
    </div>
  );
}
