import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { find } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import CONTRACT_ADDRESS from '../../contracts/address';
import { useErc20, useStakeBBet } from '../../hooks/useConstract';
import { MAX_UINT_AMOUNT } from '../../lib/constants';
import styles from '../../styles/Staking/stake-bbet.module.scss';
import LockIcon from '../Icons/LockIcon';
import RadioButton from '../UI/RadioButton';
// import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { TOKENS } from '../../constant/token';
import useModal from '../../hooks/useModal';
import { RootState } from '../../stores';
import { updateLoadingStaking } from '../../stores/LoadingSlice';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { Card, CardBody, CardHeader } from '../UI/Card';
import ConnectWallet from './ConnectWallet';
import ModalBalanceWallet from './ModalBalanceWallet';
import { STAKING_DATES } from '../../constant';

const cx = classnames.bind(styles);

const radios = STAKING_DATES;

export default function StakeBBet() {
  const context = useWeb3React<Web3Provider>();
  const { account, active } = context;
  const [isApproved, setIsApproved] = useState(false);
  const [myBalanceBBET, setMyBalanceBBET] = useState('');
  const [amount, setAmount] = useState('');
  const [lockTime, setLockTime] = useState(radios[0].key);
  const [stakeInfo, setStakeInfo] = useState({ amount: '', unLockAt: '' });
  const [optionStakeMore, setOptionStakeMore] = useState<'lockMoreBBET' | 'extendLockTime'>(
    'lockMoreBBET'
  );
  const [deductionRate, setDeductionRate] = useState('0');
  const dispatch = useDispatch();
  const loadingStaking = useSelector((state: RootState) => state.loading.loading.staking);
  const stakeBBetContract = useStakeBBet(CONTRACT_ADDRESS.STAKING);
  const stakeBBetContractWithSigner = useStakeBBet(CONTRACT_ADDRESS.STAKING, true);
  const bbetContract = useErc20(CONTRACT_ADDRESS.BBET);
  const bbetContractWithSigner = useErc20(CONTRACT_ADDRESS.BBET, true);
  const { isShowing, toggle } = useModal();

  const checkApprove = async () => {
    if (account) {
      try {
        const allowance = await bbetContract.allowance(account, CONTRACT_ADDRESS.STAKING);
        if (allowance.gt(0)) {
          setIsApproved(true);
        } else {
          setIsApproved(false);
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const getData = async () => {
    if (account) {
      try {
        const [balance, stakeInfo, deductionRate] = await Promise.all([
          bbetContract.balanceOf(account),
          stakeBBetContract.stakingInfo(account),
          stakeBBetContract.deductionRate()
        ]);

        if (balance) {
          setMyBalanceBBET(balance.toString());
        }
        setDeductionRate(new BigNumber(deductionRate.toString()).div(10).toFixed(0));

        if (stakeInfo) {
          setStakeInfo({
            amount: new BigNumber(stakeInfo.amount.toString()).div(1e18).toFixed(2),
            unLockAt: moment(stakeInfo.unlockAt.toNumber() * 1000).format('YYYY-MM-DD HH:mm:ss')
          });
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const handleChangeAmount = (e: any) => {
    const value = e.target?.value || '';
    if (!new BigNumber(value).isNaN() || value === '') {
      setAmount(value || '');
    }
  };

  const handleChangeLockTime = (key: string) => {
    setLockTime(key);
  };

  const handleMaxAmount = () => {
    setAmount(new BigNumber(myBalanceBBET).div(1e18).toFixed());
  };

  const handleApprove = async () => {
    try {
      let tx = await bbetContractWithSigner.approve(CONTRACT_ADDRESS.STAKING, MAX_UINT_AMOUNT);
      await toast.promise(tx.wait(), {
        pending: 'Process approve...',
        success: {
          render() {
            setIsApproved(true);
            return 'Approved';
          }
        },
        error: 'Approval failed'
      });
    } catch (error: any) {
      toast.error(capitalizeFirstLetter(error?.reason || error?.message));
    }
  };

  const resetDataStakeReq = () => {
    setAmount('');
    setLockTime(radios[0].key);
  };

  const handleStake = async (isFirstStake = true) => {
    try {
      let amountValue = new BigNumber(amount).multipliedBy(1e18).toFixed() || '0';
      let lockTimeValue = find(radios, { key: lockTime })?.value || 0;
      if (!isFirstStake) {
        amountValue = optionStakeMore === 'lockMoreBBET' ? amountValue : '0';
        lockTimeValue = optionStakeMore === 'extendLockTime' ? lockTimeValue : 0;
      }
      const tx = await stakeBBetContractWithSigner.staking(amountValue, lockTimeValue);
      dispatch(updateLoadingStaking(true));
      await toast.promise(tx.wait(), {
        pending: 'Process stake...',
        success: {
          render() {
            resetDataStakeReq();
            dispatch(updateLoadingStaking(false));
            return 'Staked';
          }
        },
        error: {
          render() {
            dispatch(updateLoadingStaking(false));
            return 'Stake failed';
          }
        }
      });
    } catch (error: any) {
      toast.error(capitalizeFirstLetter(error?.reason || error?.message));
    }
  };

  const showLockUntil = () => {
    const lockTimeValue = find(radios, { key: lockTime })?.value || 0;
    return moment(moment().valueOf() + lockTimeValue * 1000).format('LLLL');
  };

  useEffect(() => {
    if (stakeBBetContract && account && bbetContract && !loadingStaking) {
      getData();
    }
  }, [stakeBBetContract, account, bbetContract, loadingStaking]);

  useEffect(() => {
    if (account) {
      checkApprove();
    }
  }, [account]);

  return (
    <>
      <Card>
        <CardHeader>
          <div className={cx('header-container')}>
            <p className={cx('header-title')}>Stake ${TOKENS.BBET}</p>
            <div
              className={cx('my-balance-container')}
              onClick={() => {
                if (myBalanceBBET) {
                  toggle();
                }
              }}>
              <p>
                My Balance:{' '}
                <span className={cx('value-balance')}>
                  {!myBalanceBBET ? '---' : new BigNumber(myBalanceBBET).div(1e18).toFormat(2)}
                </span>
              </p>
              <img src="/images/tokens/bbet.svg" alt="bbet-token" className={cx('token')} />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          {!stakeInfo.amount || new BigNumber(stakeInfo.amount).lte(0) ? (
            //view first stake
            <>
              <div className={cx('stake-input-container')}>
                <div className={cx('input-container')}>
                  <LockIcon />
                  <div className={cx('text')}>
                    <p>Extend for</p>
                    <p className={cx('caption')}>14 days - 4 years</p>
                  </div>
                </div>

                <div className={cx('input-container')}>
                  <div>
                    <input className={cx('input')} value={amount} onChange={handleChangeAmount} />
                  </div>
                  <button className={cx('btn-max')} onClick={handleMaxAmount}>
                    MAX
                  </button>
                </div>
              </div>

              <div className={cx('stake-radio-container')}>
                <p>Extend for:</p>
                <div className={cx('radio-container')}>
                  {radios.map((radio) => (
                    <div key={radio.key} className={cx('radio-button')}>
                      <RadioButton
                        label={radio.label}
                        checked={radio.key === lockTime}
                        handleChange={() => {
                          handleChangeLockTime(radio.key);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>

              <div className={cx('note-container')}>
                <p className={cx('note-title')}>Lock until: {showLockUntil()}</p>
                <div>
                  <ul>
                    <li>1 BBET locked for 4 years = 1.00 xBBET </li>
                    <li>1 BBET locked for 3 years = 0.75 xBBET </li>
                    <li>1 BBET locked for 2 years = 0.50 xBBET </li>
                    <li>1 BBET locked for 1 years = 0.25 xBBET</li>
                  </ul>
                </div>
              </div>

              <div className={cx('btn-action')}>
                <ConnectWallet />
                {active && (
                  <>
                    {isApproved ? (
                      <button
                        className={cx('btn-stake', 'btn')}
                        onClick={() => {
                          handleStake(true);
                        }}
                        disabled={!amount || new BigNumber(amount).lte(0)}>
                        Stake BBET
                      </button>
                    ) : (
                      <button className={cx('btn-approve', 'btn')} onClick={handleApprove}>
                        Approve
                      </button>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            //view stake more
            <>
              <div className={cx('staked-statistic')}>
                <div className={cx('stake-info')}>
                  <p className={cx('info-key')}>Lock until</p>
                  <p>{moment(stakeInfo.unLockAt).format('LLLL')}</p>
                </div>
                <div className={cx('stake-info')}>
                  <p className={cx('info-key')}>Early unlock penalty</p>
                  <p>{deductionRate}%</p>
                </div>
              </div>

              <div className={cx('stake-more-container')}>
                <p className={cx('stake-more-title')}>Get more BBET</p>

                <div className={cx('switch-option')}>
                  <div className={cx('radio-button')}>
                    <RadioButton
                      label={'Lock more BBET'}
                      checked={optionStakeMore === 'lockMoreBBET'}
                      handleChange={() => {
                        setOptionStakeMore('lockMoreBBET');
                      }}
                    />
                  </div>
                  <div className={cx('radio-button')}>
                    <RadioButton
                      label={'Extend lock time'}
                      checked={optionStakeMore === 'extendLockTime'}
                      handleChange={() => {
                        setOptionStakeMore('extendLockTime');
                      }}
                    />
                  </div>
                </div>

                {optionStakeMore === 'lockMoreBBET' ? (
                  <div>
                    <div className={cx('stake-input-container')}>
                      <div className={cx('input-container')}>
                        <img
                          src="/images/tokens/bbet.svg"
                          alt="bbet-token"
                          className={cx('token')}
                        />
                        <div className={cx('text')}>
                          <p>BBET</p>
                          <p className={cx('caption')}>
                            Balance: {new BigNumber(myBalanceBBET).div(1e18).toFormat(2)}
                          </p>
                        </div>
                      </div>

                      <div className={cx('input-container')}>
                        <div>
                          <input
                            className={cx('input')}
                            value={amount}
                            onChange={handleChangeAmount}
                          />
                        </div>
                        <button className={cx('btn-max')} onClick={handleMaxAmount}>
                          MAX
                        </button>
                      </div>
                    </div>

                    <div className={cx('note-container')}>
                      <ul>
                        {/* <li>You need to lock a minimum amount of 100 BBET</li> */}
                        <li>You can lock maximum Unlimited BBET</li>
                        <li>Early unlock penalty fee is {deductionRate}%</li>
                      </ul>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={cx('stake-input-container')}>
                      <div className={cx('input-container')}>
                        <LockIcon />
                        <div className={cx('text')}>
                          <p>Extend for</p>
                          <p className={cx('caption')}>14 days - 4 years</p>
                        </div>
                      </div>

                      <div className={cx('input-container')}>
                        <div>
                          <input className={cx('input')} value={stakeInfo.amount} readOnly />
                        </div>
                      </div>
                    </div>

                    <div className={cx('stake-radio-container')}>
                      <p>Extend for:</p>
                      <div className={cx('radio-container')}>
                        {radios.map((radio) => (
                          <div key={radio.key} className={cx('radio-button')}>
                            <RadioButton
                              label={radio.label}
                              checked={radio.key === lockTime}
                              handleChange={() => {
                                handleChangeLockTime(radio.key);
                              }}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}

                <div className={cx('btn-action')}>
                  <button
                    className={cx('btn-stake', 'btn')}
                    onClick={() => {
                      handleStake(false);
                    }}>
                    Stake BBET
                  </button>
                </div>
              </div>
            </>
          )}
        </CardBody>
      </Card>
      <ModalBalanceWallet isShowing={isShowing} toggle={toggle} />
    </>
  );
}
