import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import { shortenAddress } from '../../lib/shortenAddress';
import ModalUI from '../UI/Modal';
import styles from '../../styles/Staking/balance-wallet.module.scss';
import classnames from 'classnames/bind';
import ArrowRightIcon from '../Icons/ArrowRightIcon';
import { useEffect, useState } from 'react';
import { useErc20 } from '../../hooks/useConstract';
import CONTRACT_ADDRESS from '../../contracts/address';
import BigNumber from 'bignumber.js';
import { ETHER_PROVIDER } from '../../lib/contract-accessor';
import { TOKENS } from '../../constant/token';

const cx = classnames.bind(styles);

interface PropsType {
  isShowing: boolean;
  toggle: () => void;
}

interface Token {
  key: string;
  title: string;
  value: string | number;
}

export default function ModalBalanceWallet({ isShowing, toggle }: PropsType) {
  const context = useWeb3React<Web3Provider>();
  const { account, activate, active, error } = context;
  const [tokens, setTokens] = useState<Token[]>([]);
  const bbetContract = useErc20(CONTRACT_ADDRESS.BBET);
  const xbtContract = useErc20(CONTRACT_ADDRESS.XBT);
  const busdContract = useErc20(CONTRACT_ADDRESS.BUSD);

  const getData = async () => {
    if (account) {
      try {
        const [bbet, xbt, busd, bnb] = await Promise.all([
          bbetContract.balanceOf(account),
          xbtContract.balanceOf(account),
          busdContract.balanceOf(account),
          ETHER_PROVIDER?.getBalance(account)
        ]);
        const tokens = [
          {
            key: TOKENS.BBET.toLowerCase(),
            title: TOKENS.BBET,
            value: new BigNumber(bbet.toString() || 0).div(1e18).toFormat(2)
          },
          {
            key: TOKENS.XBT.toLowerCase(),
            title: TOKENS.XBT,
            value: new BigNumber(xbt.toString() || 0).div(1e18).toFormat(2)
          },
          {
            key: TOKENS.BUSD.toLowerCase(),
            title: TOKENS.BUSD,
            value: new BigNumber(busd.toString() || 0).div(1e18).toFormat(2)
          },
          {
            key: TOKENS.BNB.toLowerCase(),
            title: TOKENS.BNB,
            value: new BigNumber(bnb.toString() || 0).div(1e18).toFormat(2)
          }
        ];
        setTokens(tokens);
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (isShowing) {
      getData();
    }
  }, [isShowing, account]);

  return (
    <ModalUI hide={toggle} isShowing={isShowing} title={shortenAddress(account || '')}>
      <div className={cx('root')}>
        <a
          className={cx('title-container')}
          href={process.env.REACT_APP_BSC_SCAN_URL}
          target="_blank"
          rel="noreferrer">
          <img src="/images/staking/bsc-scan-logo.svg" alt="bsc-scan-logo" />
          <p className={cx('text')}>BSC Scan</p>
          <ArrowRightIcon />
        </a>

        <div className={cx('card-container')}>
          {tokens.map((token) => (
            <div className={cx('card-item')} key={token.key}>
              <div className={cx('token-block')}>
                <img
                  src={`/images/tokens/${token.key}.svg`}
                  alt={token.key}
                  className={cx('token-logo')}
                />
                <p>{token.title}</p>
              </div>
              <p className={cx('balance')}>{token.value}</p>
            </div>
          ))}
        </div>
      </div>
    </ModalUI>
  );
}
