import { BYBET_ACCOUNT } from '../constant';
import { LOGIN_TYPE } from '../interfaces';
import { Account } from '../interfaces/Account';
import cookie from 'cookie';

export const storageService = {
  saveAccount: (data: Account) => {
    localStorage.setItem(BYBET_ACCOUNT, JSON.stringify(data));
  },
  getAccount: () => {
    const data = localStorage.getItem(BYBET_ACCOUNT);
    return data ? JSON.parse(data) : undefined;
  },
  removeAccount: () => {
    localStorage.removeItem(BYBET_ACCOUNT);
  },
  saveConnector: (connector: LOGIN_TYPE) => {
    let accountInfo: any = {};
    const data = localStorage.getItem(BYBET_ACCOUNT);
    if (data) {
      accountInfo = JSON.parse(data);
    }
    accountInfo['connector'] = connector;
    localStorage.setItem(BYBET_ACCOUNT, accountInfo);
  },
  saveExpiredTimeAccessToken: (accessToken: string, expiredTime: number) => {
    window.document.cookie = cookie.serialize('token', accessToken, {
      maxAge: expiredTime,
      path: '/'
    });
  }
};
