import React from 'react';
import userIdentity from '../services/userIdentity';
import { useAppSelector } from '../stores';

const useUserIdentity = () => {
  const { assetToken } = useAppSelector((state) => ({
    assetToken: state.account.accountInfo.accessToken
  }));
  const tokenFake =
    'eyJhbGciOiJSUzI1NiIsImtpZCI6IjIxZTZjMGM2YjRlMzA5NTI0N2MwNjgwMDAwZTFiNDMxODIzODZkNTAiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vZGVydW4tMzYzMjA3IiwiYXVkIjoiZGVydW4tMzYzMjA3IiwiYXV0aF90aW1lIjoxNjY0MjY2NTQ2LCJ1c2VyX2lkIjoiOGMyNTI1ZTctMWZjYi00ODgwLThiYjAtMzkyNGMzM2IxNDFkIiwic3ViIjoiOGMyNTI1ZTctMWZjYi00ODgwLThiYjAtMzkyNGMzM2IxNDFkIiwiaWF0IjoxNjY0MjY2NTQ2LCJleHAiOjE2NjQyNzAxNDYsImZpcmViYXNlIjp7ImlkZW50aXRpZXMiOnt9LCJzaWduX2luX3Byb3ZpZGVyIjoiY3VzdG9tIn19.Jf8a0QSQaA1Pg_95weqkf1Vcpbg71Hsax_OEdBHZqlRSCaXcbuwF_5BmyG6aeTv3YbTEq6ibquTEAsEr6kpMopNTj8onj5-33Rtk6r7eTmYChrn8qIepmQRcDTXnntkbwiwK1HxNXNALt-ev7qRqMHQD1o9p49OnmVJUBjTfwCZvRTm6c_G3X0VqG70k8O6ngK4vjOIf0aa8RWXjtvyhZR8VtePI7emCK1Py4WsAKvkzvn7l8W3-AU_ip7WHw_08iV__wQPZwje84GJFPKeOqG_NHR-EDSOqYPpJl9ZbLLGB2Sx0D1aMxcB9ZoIRlepgI6nPKuuyj1Edf2yeIyUxOg';
  return userIdentity(assetToken);
};

export default useUserIdentity;
