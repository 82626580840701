import React from 'react';

const NextLoadIcon = () => {
  return (
    <svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
        <path
          d="M17 0.999999C14.8988 0.999999 12.8183 1.41385 10.8771 2.21793C8.93585 3.022 7.17203 4.20055 5.68629 5.68629C4.20055 7.17203 3.022 8.93586 2.21793 10.8771C1.41385 12.8183 1 14.8989 1 17"
          stroke="#FFE036"
          strokeLinecap="round"
        />
        <path
          d="M24.5792 17L11.1055 17"
          stroke="#FFE036"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.5264 22.0527L24.579 17.0001L19.5264 11.9475"
          stroke="#FFE036"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default NextLoadIcon;
