import classnames from 'classnames/bind';
import styles from '../styles/coming-soon.module.scss';

const cx = classnames.bind(styles);

export default function ComingSoon() {
  return (
    <div className={cx('root')}>
      <img src="/images/coming-soon-img.png" alt="coming-soon" />
    </div>
  );
}
