import { Web3Provider } from '@ethersproject/providers';
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector';
import classnames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useEagerConnect } from '../../hooks/useEagerConnect';
import { useInactiveListener } from '../../hooks/useInactiveListener';
import { injected } from '../../lib/connectors';
import styles from '../../styles/Staking/stake-bbet.module.scss';

const cx = classnames.bind(styles);

enum ConnectorNames {
  Injected = 'Metamask'
  // Trust = 'Trust Wallet'
}

const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected
  // [ConnectorNames.Trust]: injected
};

export default function ConnectWallet() {
  const context = useWeb3React<Web3Provider>();
  const { connector, activate, error, active } = context;
  // handle logic to recognize the connector currently being activated
  const [activatingConnector, setActivatingConnector] = useState<any>();

  const handleConnectWallet = () => {
    setActivatingConnector(connectorsByName['Metamask']);
    activate(connectorsByName['Metamask']);
  };

  useEffect(() => {
    if (error) {
      toast.error(getErrorMessage(error));
    }
  }, [error]);

  useEffect(() => {
    if (activatingConnector && activatingConnector === connector) {
      setActivatingConnector(undefined);
    }
  }, [activatingConnector, connector]);

  // // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
  const triedEager = useEagerConnect();

  // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
  useInactiveListener(!triedEager || !!activatingConnector);

  return (
    <>
      {!active && (
        <button className={cx('btn-connect-wallet', 'btn')} onClick={handleConnectWallet}>
          Connect Wallet
        </button>
      )}
    </>
  );
}

function getErrorMessage(error: Error) {
  if (error instanceof NoEthereumProviderError) {
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.';
  } else if (error instanceof UnsupportedChainIdError) {
    return "You're connected to an unsupported network.";
  } else if (error instanceof UserRejectedRequestErrorInjected) {
    return 'Please authorize this website to access your Ethereum account.';
  } else {
    return 'An unknown error occurred. Check the console for more details.';
  }
}
