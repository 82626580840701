export const listGame = [
  {
    key: 'crash',
    isComing: false,
    value: 'Crash'
  },
  {
    key: 'ring_of_fortune',
    isComing: false,
    value: 'Ring of Fortune'
  },
  {
    key: 'classic_dice',
    isComing: false,
    value: 'Classic Dice'
  },
  {
    key: 'baccarat',
    isComing: true,
    value: 'Baccarat'
  },
  {
    key: 'roulette',
    isComing: true,
    value: 'Roulette'
  },
  {
    key: 'monster',
    isComing: true,
    value: 'Monster'
  },
  {
    key: 'poker',
    isComing: true,
    value: 'Poker'
  },
  {
    key: 'fish_cards',
    isComing: true,
    value: 'Fish Cards'
  },
  {
    key: 'slots',
    isComing: true,
    value: 'Slots'
  },
  {
    key: 'augu_poker',
    isComing: true,
    value: 'Augu Poker'
  }
];

export const topWining = [
  {
    top: 1,
    profit: 68587,
    game: 'classic dice'
  },
  {
    top: 2,
    profit: 25008,
    game: 'crash'
  },
  {
    top: 3,
    profit: 14254,
    game: 'ring of fortune'
  }
];

export enum TAB_HOME {
  LOPPY = 'loppy',
  ORIGINAL = 'original',
  LIVE_CASINO = 'live_casino',
  TABLE_GAME = 'table_game',
  GAME_SHOW = 'game_show',
  SLOTS = 'slots'
}
