import React from 'react';
import LobbyIcon from '../Icons/LobbyIcon';
import styles from '../../styles/components/home/tabs.module.scss';
import classNames from 'classnames/bind';
import OriginalIcon from '../Icons/OriginalIcon';
import SlotsIcon from '../Icons/SlotsIcon';
import LiveCasinoIcon from '../Icons/LiveCasinoIcon';
import PokerCardIcon from '../Icons/PokerCardIcon';
import GameShow from '../Icons/GameShow';
import { TAB_HOME } from '../../constant/data-home-page';

interface PropsType {
  setTabActive: Function;
  tabActive: string;
}

const cx = classNames.bind(styles);

const tabList = [
  {
    key: TAB_HOME.LOPPY,
    name: 'Lobby',
    getIcon: (active: boolean) => <LobbyIcon active={active} />
  },
  {
    key: TAB_HOME.ORIGINAL,
    name: 'Original',
    getIcon: (active: boolean) => <OriginalIcon active={active} />
  },
  {
    key: TAB_HOME.SLOTS,
    name: 'Slots',
    getIcon: (active: boolean) => <SlotsIcon active={active} />
  },
  {
    key: TAB_HOME.LIVE_CASINO,
    name: 'Live Casino',
    getIcon: (active: boolean) => <LiveCasinoIcon active={active} />
  },
  {
    key: TAB_HOME.TABLE_GAME,
    name: 'Table Game',
    getIcon: (active: boolean) => <PokerCardIcon active={active} />
  },
  {
    key: TAB_HOME.GAME_SHOW,
    name: 'Game Show',
    getIcon: (active: boolean) => <GameShow active={active} />
  }
];

const Tabs = ({ setTabActive, tabActive }: PropsType) => {
  return (
    <div className={cx('wrapper')}>
      <ul className={cx('tab-list')}>
        {tabList.map((item) => {
          const isActive = tabActive === item.key;
          return (
            <li
              onClick={() => setTabActive(item.key)}
              key={item.key}
              className={cx('item', isActive && 'item-active')}>
              <div className={cx('icon')}>{item.getIcon(isActive)}</div>
              <div className={cx('name')}>{item.name}</div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Tabs;
