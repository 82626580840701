import React from 'react';
interface PropsType {
  active?: boolean;
}
const PokerCardIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_610_2052)">
        <path
          d="M22.4532 1.62451L13.4031 0.0287451C12.3852 -0.150739 11.4145 0.528948 11.2351 1.54684L11.1494 2.0326C12.4582 2.19385 13.5639 3.1367 13.9121 4.43584L14.4652 6.49998C14.758 6.37299 15.09 6.32724 15.4278 6.38682C16.0689 6.49989 16.5608 6.96128 16.744 7.54192C17.1148 7.05896 17.7348 6.7936 18.3759 6.90667C19.2935 7.06848 19.9062 7.94349 19.7444 8.86107C19.3929 10.8549 17.2533 12.4574 16.238 13.1165L17.7882 18.9019C17.8713 19.2121 17.9059 19.5238 17.8983 19.8296L19.2025 20.0596C20.2204 20.239 21.1911 19.5594 21.3706 18.5415L23.9712 3.79267C24.1508 2.77468 23.4711 1.80399 22.4532 1.62451Z"
          fill={active ? '#FFE036' : '#7F7F7F'}
        />
        <path
          d="M12.5563 4.79922C12.3322 3.96306 11.5761 3.41157 10.7497 3.41162C10.5894 3.41162 10.4264 3.43239 10.2641 3.47584L1.38761 5.85428C0.389217 6.12179 -0.203282 7.14803 0.0642332 8.14642L3.94037 22.6125C4.16444 23.4486 4.92058 24.0001 5.74694 24.0001C5.90725 24.0001 6.07028 23.9793 6.23247 23.9358L15.109 21.5574C16.1074 21.2898 16.6999 20.2636 16.4324 19.2652L12.5563 4.79922ZM5.64892 14.6875C5.4984 14.5551 5.4422 14.3453 5.50633 14.1554L6.86364 10.1364C6.97717 9.80022 7.39633 9.6879 7.66276 9.92228L10.8477 12.7242C10.9982 12.8566 11.0545 13.0665 10.9903 13.2564L9.63301 17.2753C9.51948 17.6115 9.10033 17.7239 8.83389 17.4894L5.64892 14.6875Z"
          fill={active ? '#FFE036' : '#7F7F7F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_610_2052">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PokerCardIcon;
