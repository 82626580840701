export default function StakingIcon() {
  return (
    <svg width={27} height={26} viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_512_194)">
        <path
          d="M23.5132 6.62817H24.7015V15.9407H23.5132V6.62817Z"
          fill="url(#paint0_linear_512_194)"
        />
        <path
          d="M23.5132 7.8487L24.7015 9.03705V6.62817H23.5132V7.8487Z"
          fill="url(#paint1_linear_512_194)"
        />
        <path
          d="M24.1071 7.93546C25.1956 7.93546 26.078 7.05305 26.078 5.96455C26.078 4.87606 25.1956 3.99365 24.1071 3.99365C23.0186 3.99365 22.1362 4.87606 22.1362 5.96455C22.1362 7.05305 23.0186 7.93546 24.1071 7.93546Z"
          fill="url(#paint2_linear_512_194)"
        />
        <path
          d="M24.1073 7.60934C25.0157 7.60934 25.7522 6.87291 25.7522 5.96446C25.7522 5.05602 25.0157 4.31958 24.1073 4.31958C23.1988 4.31958 22.4624 5.05602 22.4624 5.96446C22.4624 6.87291 23.1988 7.60934 24.1073 7.60934Z"
          fill="url(#paint3_linear_512_194)"
        />
        <path
          d="M24.9889 4.20142C25.1219 4.46672 25.1968 4.76613 25.1968 5.08307C25.1968 6.17155 24.3144 7.05397 23.2259 7.05397C22.9089 7.05397 22.6095 6.97903 22.3442 6.84613C22.6679 7.492 23.3358 7.93537 24.1073 7.93537C25.1958 7.93537 26.0782 7.05295 26.0782 5.96447C26.0782 5.19293 25.6348 4.52499 24.9889 4.20142Z"
          fill="url(#paint4_linear_512_194)"
        />
        <path
          d="M23.8895 17.6373H23.001V14.447H23.8895C24.4375 14.447 24.8817 14.8913 24.8817 15.4393V16.6451C24.8817 17.1931 24.4375 17.6373 23.8895 17.6373Z"
          fill="url(#paint5_linear_512_194)"
        />
        <path
          d="M23.353 14.447H23.001V17.6373H23.8895C24.4375 17.6373 24.8817 17.1931 24.8817 16.6451V15.9757L23.353 14.447Z"
          fill="url(#paint6_linear_512_194)"
        />
        <path
          d="M22.4317 18.4582H20.5103V13.626H22.4317C22.9438 13.626 23.3589 14.0411 23.3589 14.5533V17.5309C23.3589 18.0431 22.9438 18.4582 22.4317 18.4582Z"
          fill="url(#paint7_linear_512_194)"
        />
        <path
          d="M21.4835 13.6411L21.5057 13.626H20.5103V18.4582H22.4317C22.9438 18.4582 23.3589 18.0431 23.3589 17.5309V15.5164L21.4835 13.6411Z"
          fill="url(#paint8_linear_512_194)"
        />
        <path
          d="M13.3579 8.30614H8.20508V6.57005C8.20508 5.14714 9.35856 3.99365 10.7815 3.99365C12.2044 3.99365 13.3579 5.14714 13.3579 6.57005V8.30614H13.3579Z"
          fill="url(#paint9_linear_512_194)"
        />
        <path
          d="M11.9613 4.27941C11.4826 4.03251 10.9212 4.03251 10.4424 4.27941C9.61277 4.70724 9.04541 5.57227 9.04541 6.57011V8.3062H13.3584V6.57011C13.3583 5.57227 12.791 4.70729 11.9613 4.27941Z"
          fill="url(#paint10_linear_512_194)"
        />
        <path
          d="M10.7817 3.99365C10.6024 3.99365 10.4273 4.01206 10.2583 4.04698C11.4302 4.28883 12.3113 5.32653 12.3113 6.5701V8.30619H13.3581V6.5701C13.3581 5.14713 12.2046 3.99365 10.7817 3.99365Z"
          fill="url(#paint11_linear_512_194)"
        />
        <path
          d="M10.7818 6.78465C11.7755 6.78465 12.5811 6.20244 12.5811 5.48425C12.5811 4.76605 11.7755 4.18384 10.7818 4.18384C9.78802 4.18384 8.98242 4.76605 8.98242 5.48425C8.98242 6.20244 9.78802 6.78465 10.7818 6.78465Z"
          fill="url(#paint12_linear_512_194)"
        />
        <path
          d="M14.1978 8.82088H7.36523V7.41749C7.36523 6.98048 7.7195 6.62622 8.1565 6.62622H13.4066C13.8436 6.62622 14.1978 6.98048 14.1978 7.41749V8.82088Z"
          fill="url(#paint13_linear_512_194)"
        />
        <path
          d="M14.1979 8.82091V7.41752C14.1979 7.25331 14.1479 7.10083 14.0623 6.9744C13.9582 6.92403 13.8419 6.89502 13.7186 6.89502H8.46851C8.03151 6.89502 7.67725 7.24928 7.67725 7.68629V8.82091H14.1979Z"
          fill="url(#paint14_linear_512_194)"
        />
        <path
          d="M19.1783 24.2369H2.3847C1.64905 24.2369 1.05273 23.6406 1.05273 22.9049V9.17913C1.05273 8.44349 1.64905 7.84717 2.3847 7.84717H19.1783C19.9138 7.84717 20.5102 8.44349 20.5102 9.17913V22.9049C20.5102 23.6405 19.9138 24.2369 19.1783 24.2369Z"
          fill="url(#paint15_linear_512_194)"
        />
        <path
          d="M14.5467 10.0363H7.01694C6.67078 10.0363 6.39014 9.75568 6.39014 9.40952C6.39014 9.06336 6.67078 8.78271 7.01694 8.78271H14.5467C14.8929 8.78271 15.1735 9.06336 15.1735 9.40952C15.1735 9.75573 14.8929 10.0363 14.5467 10.0363Z"
          fill="url(#paint16_linear_512_194)"
        />
        <path
          d="M2.94964 8.41211C2.21404 8.41211 1.61768 9.00843 1.61768 9.74407V23.4699C1.61768 23.6922 1.67243 23.9016 1.76873 24.0859C1.95297 24.1822 2.1624 24.2369 2.38473 24.2369H19.1783C19.9139 24.2369 20.5102 23.6406 20.5102 22.905V9.17916C20.5102 8.95683 20.4555 8.74746 20.3592 8.56316C20.1749 8.46686 19.9655 8.41211 19.7432 8.41211H2.94964Z"
          fill="url(#paint17_linear_512_194)"
        />
        <path
          d="M1.05322 20.3718V21.0625L4.22782 24.2371H19.1787C19.9138 24.2371 20.5101 23.6407 20.5101 22.905V20.3718H1.05322Z"
          fill="url(#paint18_linear_512_194)"
        />
        <path
          d="M0.078125 12.0302V20.0541C0.078125 20.5533 0.445541 20.9773 0.939949 21.0467C7.4868 21.9656 14.0765 21.9657 20.6234 21.0467C21.1178 20.9773 21.4853 20.5533 21.4853 20.0541C21.4853 17.3795 21.4853 14.7048 21.4853 12.0302C21.4853 11.5309 21.1178 11.107 20.6234 11.0376C14.0766 10.1187 7.4868 10.1186 0.939949 11.0376C0.445541 11.107 0.078125 11.5309 0.078125 12.0302H0.078125Z"
          fill="url(#paint19_linear_512_194)"
        />
        <path
          d="M1.39698 11.4185C0.902623 11.4879 0.535156 11.9119 0.535156 12.4111V20.435C0.535156 20.6349 0.594803 20.8221 0.697172 20.98C0.773592 21.0116 0.85465 21.0347 0.939839 21.0466C7.48669 21.9656 14.0764 21.9656 20.6233 21.0466C21.1177 20.9773 21.4851 20.5532 21.4851 20.054C21.4851 17.3794 21.4851 14.7048 21.4851 12.0301C21.4851 11.8302 21.4255 11.643 21.3231 11.4852C21.2467 11.4536 21.1656 11.4304 21.0805 11.4185C14.5336 10.4995 7.94383 10.4995 1.39698 11.4185Z"
          fill="url(#paint20_linear_512_194)"
        />
        <path
          d="M6.70314 12.5077V19.5762C6.70314 19.7554 6.52838 19.8981 6.32166 19.8866C4.86402 19.8053 3.40675 19.6843 1.95829 19.5247C1.7791 19.505 1.64502 19.3725 1.64502 19.2163V12.8678C1.64502 12.7115 1.7791 12.579 1.95829 12.5593C3.40675 12.3997 4.86402 12.2787 6.32166 12.1974C6.52838 12.1859 6.70314 12.3286 6.70314 12.5077Z"
          fill="url(#paint21_linear_512_194)"
        />
        <path
          d="M13.3105 12.4138V19.6702C13.3105 19.8377 13.157 19.9757 12.9635 19.981C12.2348 20.0013 11.5072 20.0113 10.782 20.0113C10.7815 20.0113 10.7815 20.0113 10.7815 20.0113C10.0558 20.0113 9.32813 20.0013 8.59947 19.981C8.40594 19.9756 8.25244 19.8377 8.25244 19.6702V12.4138C8.25244 12.2463 8.40599 12.1083 8.59947 12.103C9.32818 12.0827 10.0559 12.0728 10.7815 12.0728C11.5071 12.0728 12.2348 12.0827 12.9635 12.103C13.157 12.1083 13.3105 12.2463 13.3105 12.4138Z"
          fill="url(#paint22_linear_512_194)"
        />
        <path
          d="M19.9185 12.8678V19.2163C19.9185 19.3726 19.7844 19.505 19.6052 19.5247C18.1567 19.6843 16.6995 19.8053 15.2418 19.8866C15.0352 19.8981 14.8604 19.7555 14.8604 19.5762V12.5078C14.8604 12.3286 15.0351 12.1859 15.2418 12.1974C16.6995 12.2787 18.1567 12.3997 19.6052 12.5593C19.7844 12.5791 19.9185 12.7115 19.9185 12.8678Z"
          fill="url(#paint23_linear_512_194)"
        />
        <path
          d="M6.70314 12.508V14.1631C5.01544 14.2605 3.32885 14.4192 1.64502 14.6388V12.8681C1.64502 12.7116 1.77889 12.5794 1.95809 12.5594C3.4068 12.4001 4.86387 12.279 6.32145 12.1977C6.52838 12.1861 6.70314 12.3288 6.70314 12.508Z"
          fill="url(#paint24_linear_512_194)"
        />
        <path
          d="M13.3106 12.4138V14.0905C11.625 14.0296 9.9379 14.0296 8.25244 14.0905V12.4138C8.25244 12.2462 8.4062 12.1085 8.59926 12.1029C9.32833 12.0825 10.0557 12.0725 10.7815 12.0725C11.5072 12.0725 12.2346 12.0825 12.9637 12.1029C13.1568 12.1085 13.3106 12.2462 13.3106 12.4138Z"
          fill="url(#paint25_linear_512_194)"
        />
        <path
          d="M19.9185 12.8681V14.6388C18.2346 14.4192 16.5481 14.2604 14.8604 14.1631V12.508C14.8604 12.3288 15.0352 12.1861 15.242 12.1977C16.6996 12.279 18.1566 12.4001 19.6054 12.5594C19.7846 12.5794 19.9185 12.7116 19.9185 12.8681Z"
          fill="url(#paint26_linear_512_194)"
        />
        <path
          d="M6.70295 19.5763V14.099L6.02425 13.4203C5.99534 13.3806 5.95415 13.3426 5.8997 13.3063C5.78362 13.2289 5.66621 13.1902 5.54758 13.1902H2.8002C2.66596 13.1902 2.54478 13.2289 2.43645 13.3063C2.32812 13.3837 2.27393 13.4688 2.27393 13.5616V14.7612C2.27393 14.8523 2.31925 14.9313 2.40958 14.9982L3.88868 16.4779L2.96267 18.0814C2.92653 18.1485 2.90853 18.2156 2.90853 18.2826C2.90853 18.4406 2.97705 18.576 3.1125 18.6895L4.15995 19.737C4.87969 19.7965 5.60055 19.8465 6.32146 19.8867C6.52819 19.8982 6.70295 19.7555 6.70295 19.5763Z"
          fill="url(#paint27_linear_512_194)"
        />
        <path
          d="M2.27393 14.7612V13.5616C2.27393 13.4688 2.32807 13.3836 2.43645 13.3063C2.54484 13.2289 2.66596 13.1902 2.8002 13.1902H5.54763C5.66626 13.1902 5.78367 13.2289 5.89975 13.3063C6.01589 13.3837 6.0739 13.4688 6.0739 13.5616V14.529C6.0739 14.7097 5.99131 14.9547 5.82624 15.2643L4.01521 18.7314C3.95837 18.8398 3.84494 18.894 3.67466 18.894C3.47854 18.894 3.30189 18.8347 3.14457 18.716C2.98709 18.5973 2.90853 18.4528 2.90853 18.2826C2.90853 18.2156 2.92653 18.1484 2.96267 18.0813L4.86653 14.7844V14.2427H3.48124V14.7612C3.48124 14.8645 3.4193 14.9522 3.29547 15.0243C3.17164 15.0966 3.02966 15.1327 2.86984 15.1327C2.70466 15.1327 2.56416 15.0966 2.44802 15.0243C2.33194 14.9522 2.27393 14.8645 2.27393 14.7612Z"
          fill="url(#paint28_linear_512_194)"
        />
        <path
          d="M3.67502 18.7162C3.51708 18.7162 3.37877 18.6697 3.25208 18.5741C3.10388 18.4624 3.0868 18.3544 3.0868 18.2827C3.0868 18.2458 3.0972 18.2082 3.11856 18.1679L5.04481 14.8322V14.0649H3.30357V14.7613C3.30357 14.7962 3.27079 14.833 3.20615 14.8707C3.10913 14.9273 2.99927 14.9549 2.87009 14.9549C2.73754 14.9549 2.63038 14.9282 2.54238 14.8734C2.45215 14.8173 2.45215 14.7767 2.45215 14.7613V13.5617C2.45215 13.5201 2.50726 13.4746 2.54014 13.4511C2.61829 13.3953 2.70348 13.3682 2.8005 13.3682H5.54793C5.63144 13.3682 5.71433 13.3964 5.80136 13.4544C5.8608 13.494 5.89628 13.5341 5.89628 13.5617V14.5291C5.89628 14.6323 5.85693 14.8291 5.66957 15.1806L3.85788 18.6492C3.84922 18.6655 3.82271 18.7162 3.67502 18.7162Z"
          fill="url(#paint29_linear_512_194)"
        />
        <path
          d="M13.311 14.0989V19.6703C13.311 19.8379 13.1572 19.9756 12.9641 19.9812C12.3219 19.9994 11.6808 20.0089 11.0419 20.0111L9.72648 18.6956L9.72041 18.6896C9.58491 18.5762 9.51629 18.4406 9.51629 18.2824C9.51629 18.2155 9.53454 18.1485 9.57048 18.0816L10.4965 16.478L9.01734 14.9983C8.92716 14.9313 8.88184 14.8522 8.88184 14.761V13.5617C8.88184 13.4688 8.93603 13.3836 9.04446 13.3061C9.1529 13.2287 9.27403 13.1899 9.40846 13.1899H12.1555C12.2744 13.1899 12.3917 13.2287 12.5079 13.3061C12.5621 13.3426 12.6036 13.3808 12.6323 13.4201L13.311 14.0989Z"
          fill="url(#paint30_linear_512_194)"
        />
        <path
          d="M13.3106 14.0988V19.6702C13.3106 19.8378 13.1567 19.9755 12.9637 19.9811C12.3215 19.9993 11.6804 20.0088 11.0415 20.011L9.72607 18.6955C9.73438 18.7022 9.7432 18.7093 9.75207 18.716C9.90971 18.835 10.0862 18.8941 10.2826 18.8941C10.4524 18.8941 10.5658 18.84 10.6228 18.7315L12.4338 15.2643C12.5987 14.9545 12.6817 14.7095 12.6817 14.5292V13.5616C12.6817 13.513 12.6656 13.466 12.633 13.4211L13.3106 14.0988Z"
          fill="url(#paint31_linear_512_194)"
        />
        <path
          d="M8.88184 14.7612V13.5616C8.88184 13.4688 8.93598 13.3836 9.04436 13.3063C9.15275 13.2289 9.27388 13.1902 9.40811 13.1902H12.1555C12.2742 13.1902 12.3916 13.2289 12.5077 13.3063C12.6237 13.3837 12.6818 13.4688 12.6818 13.5616V14.529C12.6818 14.7097 12.5992 14.9547 12.4342 15.2643L10.6232 18.7314C10.5663 18.8398 10.4529 18.894 10.2826 18.894C10.0865 18.894 9.9098 18.8347 9.75248 18.716C9.595 18.5973 9.51644 18.4528 9.51644 18.2826C9.51644 18.2156 9.53444 18.1484 9.57058 18.0813L11.4744 14.7844V14.2427H10.0891V14.7612C10.0891 14.8645 10.0272 14.9522 9.90333 15.0243C9.7795 15.0966 9.63752 15.1327 9.47769 15.1327C9.31252 15.1327 9.17202 15.0966 9.05588 15.0243C8.93985 14.9522 8.88184 14.8645 8.88184 14.7612Z"
          fill="url(#paint32_linear_512_194)"
        />
        <path
          d="M10.2824 18.7162C10.1245 18.7162 9.98619 18.6697 9.8595 18.5741C9.7113 18.4624 9.69417 18.3544 9.69417 18.2827C9.69417 18.2458 9.70457 18.2082 9.72594 18.1679L11.6522 14.8322V14.0649H9.91099V14.7613C9.91099 14.7962 9.87821 14.833 9.81357 14.8707C9.71655 14.9273 9.60669 14.9549 9.47751 14.9549C9.34496 14.9549 9.2378 14.9282 9.14981 14.8734C9.05957 14.8173 9.05957 14.7767 9.05957 14.7613V13.5617C9.05957 13.5201 9.11468 13.4746 9.14756 13.4511C9.22572 13.3953 9.3109 13.3682 9.40792 13.3682H12.1554C12.2389 13.3682 12.3218 13.3964 12.4088 13.4544C12.4682 13.494 12.5037 13.5341 12.5037 13.5617V14.5291C12.5037 14.6323 12.4643 14.8291 12.277 15.1806L10.4653 18.6492C10.4567 18.6655 10.4301 18.7162 10.2824 18.7162Z"
          fill="url(#paint33_linear_512_194)"
        />
        <path
          d="M19.9184 14.0989V19.2162C19.9184 19.3727 19.7846 19.5049 19.6054 19.5249C18.8685 19.6062 18.1284 19.677 17.3883 19.7384C17.3877 19.7384 17.3877 19.7384 17.3872 19.7384C17.3833 19.7378 17.3794 19.7378 17.3755 19.7373L16.3278 18.6896C16.1923 18.5762 16.1237 18.4406 16.1237 18.2824C16.1237 18.2155 16.142 18.1485 16.1779 18.0816L17.1039 16.478L15.6248 14.9983C15.5346 14.9313 15.4893 14.8522 15.4893 14.761V13.5617C15.4893 13.4688 15.5435 13.3836 15.6519 13.3061C15.7603 13.2287 15.8815 13.1899 16.0159 13.1899H18.7629C18.8819 13.1899 18.9991 13.2287 19.1153 13.3061C19.1695 13.3426 19.211 13.3808 19.2397 13.4201L19.9184 14.0989Z"
          fill="url(#paint34_linear_512_194)"
        />
        <path
          d="M19.9185 14.0988V19.2161C19.9185 19.3726 19.7846 19.5048 19.6054 19.5248C18.868 19.6061 18.1279 19.6769 17.3872 19.7383C17.3834 19.7377 17.3795 19.7377 17.3756 19.7372L16.334 18.6955C16.3423 18.7022 16.3511 18.7093 16.36 18.716C16.5176 18.835 16.6941 18.8941 16.8905 18.8941C17.0603 18.8941 17.1737 18.84 17.2307 18.7315L19.0418 15.2643C19.2066 14.9545 19.2896 14.7095 19.2896 14.5292V13.5616C19.2896 13.513 19.2735 13.466 19.2409 13.4211L19.9185 14.0988Z"
          fill="url(#paint35_linear_512_194)"
        />
        <path
          d="M15.4893 14.7612V13.5616C15.4893 13.4688 15.5434 13.3836 15.6518 13.3063C15.7602 13.2289 15.8813 13.1902 16.0155 13.1902H18.763C18.8816 13.1902 18.999 13.2289 19.1151 13.3063C19.2312 13.3837 19.2892 13.4688 19.2892 13.5616V14.529C19.2892 14.7097 19.2066 14.9547 19.0416 15.2643L17.2306 18.7314C17.1738 18.8398 17.0603 18.894 16.89 18.894C16.6939 18.894 16.5172 18.8347 16.36 18.716C16.2025 18.5973 16.1239 18.4528 16.1239 18.2826C16.1239 18.2156 16.1419 18.1484 16.1781 18.0813L18.0819 14.7844V14.2427H16.6966V14.7612C16.6966 14.8645 16.6346 14.9522 16.5108 15.0243C16.387 15.0966 16.245 15.1327 16.0852 15.1327C15.92 15.1327 15.7795 15.0966 15.6634 15.0243C15.5473 14.9522 15.4893 14.8645 15.4893 14.7612Z"
          fill="url(#paint36_linear_512_194)"
        />
        <path
          d="M16.8898 18.7162C16.7319 18.7162 16.5936 18.6697 16.4669 18.5741C16.3187 18.4624 16.3016 18.3544 16.3016 18.2827C16.3016 18.2458 16.312 18.2082 16.3334 18.1679L18.2596 14.8322V14.0649H16.5184V14.7613C16.5184 14.7962 16.4856 14.833 16.421 14.8707C16.324 14.9273 16.2141 14.9549 16.0849 14.9549C15.9524 14.9549 15.8452 14.9282 15.7572 14.8734C15.667 14.8173 15.667 14.7767 15.667 14.7613V13.5617C15.667 13.5201 15.7221 13.4746 15.755 13.4511C15.8331 13.3953 15.9183 13.3682 16.0153 13.3682H18.7628C18.8463 13.3682 18.9292 13.3964 19.0161 13.4544C19.0756 13.494 19.1111 13.5341 19.1111 13.5617V14.5291C19.1111 14.6323 19.0717 14.8291 18.8844 15.1806L17.0727 18.6492C17.0641 18.6655 17.0375 18.7162 16.8898 18.7162Z"
          fill="url(#paint37_linear_512_194)"
        />
        <path
          d="M6.70314 14.0988V19.5762C6.70314 19.7554 6.52832 19.8982 6.32144 19.8865C5.60068 19.8467 4.87992 19.7963 4.16023 19.7372L3.11865 18.6955C3.12696 18.7022 3.13578 18.7093 3.14465 18.716C3.30228 18.835 3.47878 18.8941 3.67515 18.8941C3.84497 18.8941 3.9584 18.84 4.01535 18.7315L5.82643 15.2643C5.99124 14.9545 6.07424 14.7095 6.07424 14.5292V13.5616C6.07424 13.513 6.05818 13.466 6.02555 13.4211L6.70314 14.0988Z"
          fill="url(#paint38_linear_512_194)"
        />
        <path
          d="M11.1801 2.9909V2.16135C11.1801 1.94132 11.0017 1.76294 10.7817 1.76294C10.5617 1.76294 10.3833 1.94132 10.3833 2.16135V2.9909C10.3833 3.21099 10.5617 3.38932 10.7817 3.38932C11.0017 3.38932 11.1801 3.21099 11.1801 2.9909Z"
          fill="url(#paint39_linear_512_194)"
        />
        <path
          d="M13.9912 6.04536H14.8207C15.0408 6.04536 15.2192 5.86698 15.2192 5.64695C15.2192 5.42692 15.0408 5.24854 14.8207 5.24854H13.9912C13.7712 5.24854 13.5928 5.42692 13.5928 5.64695C13.5927 5.86698 13.7712 6.04536 13.9912 6.04536Z"
          fill="url(#paint40_linear_512_194)"
        />
        <path
          d="M6.74265 6.04536H7.57225C7.79229 6.04536 7.97067 5.86698 7.97067 5.64695C7.97067 5.42692 7.79229 5.24854 7.57225 5.24854H6.74265C6.52262 5.24854 6.34424 5.42692 6.34424 5.64695C6.34419 5.86698 6.52257 6.04536 6.74265 6.04536Z"
          fill="url(#paint41_linear_512_194)"
        />
        <path
          d="M13.2671 4.05699L13.8537 3.47041C14.0093 3.31482 14.0093 3.06257 13.8537 2.90698C13.6981 2.75139 13.4458 2.75139 13.2902 2.90698L12.7036 3.49356C12.548 3.64915 12.548 3.9014 12.7036 4.05704C12.8592 4.21259 13.1115 4.21259 13.2671 4.05699Z"
          fill="url(#paint42_linear_512_194)"
        />
        <path
          d="M7.70949 3.47022L8.29607 4.0568C8.45167 4.21239 8.70392 4.21239 8.85951 4.0568C9.0151 3.90121 9.0151 3.64891 8.85951 3.49331L8.27293 2.90673C8.11734 2.75114 7.86509 2.75114 7.70944 2.90673C7.5539 3.06238 7.55385 3.31463 7.70949 3.47022Z"
          fill="url(#paint43_linear_512_194)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_512_194"
          x1="23.6663"
          y1="11.2845"
          x2="24.6694"
          y2="11.2845"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4F2FF" />
          <stop offset={1} stopColor="#B2CBFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_512_194"
          x1="25.6886"
          y1="8.83027"
          x2="23.0907"
          y2="6.21498"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_512_194"
          x1="23.3697"
          y1="5.22712"
          x2="25.9223"
          y2="7.77981"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#91F27F" />
          <stop offset={1} stopColor="#00B302" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_512_194"
          x1="24.3036"
          y1="6.16079"
          x2="22.52"
          y2="4.37719"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#91F27F" stopOpacity={0} />
          <stop offset={1} stopColor="#FFED82" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_512_194"
          x1="24.0324"
          y1="6.77144"
          x2="29.2877"
          y2="2.50275"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#005A01" stopOpacity={0} />
          <stop offset={1} stopColor="#005A01" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_512_194"
          x1="23.9413"
          y1="15.4356"
          x2="23.9413"
          y2="17.9164"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#9B62CD" />
          <stop offset="0.3003" stopColor="#985DCB" />
          <stop offset="0.6716" stopColor="#8F4EC7" />
          <stop offset={1} stopColor="#833AC1" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_512_194"
          x1="24.3925"
          y1="16.6847"
          x2="22.1449"
          y2="15.4966"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#833AC1" stopOpacity={0} />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_512_194"
          x1="21.9346"
          y1="15.1234"
          x2="21.9346"
          y2="18.881"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_512_194"
          x1="22.8134"
          y1="16.9964"
          x2="19.2471"
          y2="15.1113"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F82814" stopOpacity={0} />
          <stop offset={1} stopColor="#C0272D" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_512_194"
          x1="10.7815"
          y1="4.69443"
          x2="10.7815"
          y2="9.29786"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_512_194"
          x1="10.8562"
          y1="6.54284"
          x2="9.1517"
          y2="4.17975"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" stopOpacity={0} />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_512_194"
          x1="11.8082"
          y1="4.06961"
          x2="11.8082"
          y2="8.67309"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F82814" stopOpacity={0} />
          <stop offset={1} stopColor="#C0272D" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_512_194"
          x1="10.7818"
          y1="5.6067"
          x2="10.7818"
          y2="1.3945"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_512_194"
          x1="10.7815"
          y1="6.98288"
          x2="10.7815"
          y2="9.32558"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8A7889" />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_512_194"
          x1="11.4676"
          y1="8.75636"
          x2="9.16496"
          y2="5.44891"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8A7889" stopOpacity={0} />
          <stop offset={1} stopColor="#9D8B91" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_512_194"
          x1="5.61084"
          y1="10.8373"
          x2="15.9283"
          y2="21.2227"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#9B62CD" />
          <stop offset="0.3003" stopColor="#985DCB" />
          <stop offset="0.6716" stopColor="#8F4EC7" />
          <stop offset={1} stopColor="#833AC1" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_512_194"
          x1="10.7818"
          y1="9.82547"
          x2="10.7818"
          y2="8.43452"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#9B62CD" />
          <stop offset="0.1454" stopColor="#965ACB" />
          <stop offset="0.672" stopColor="#8843C4" />
          <stop offset={1} stopColor="#833AC1" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_512_194"
          x1="15.5714"
          y1="19.4169"
          x2="-4.28096"
          y2="5.79661"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#9B62CD" stopOpacity={0} />
          <stop offset={1} stopColor="#FF75C8" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_512_194"
          x1="10.8816"
          y1="25.1108"
          x2="10.7553"
          y2="19.5535"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#833AC1" stopOpacity={0} />
          <stop offset={1} stopColor="#6E566E" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_512_194"
          x1="10.4307"
          y1="13.6438"
          x2="11.7422"
          y2="22.6057"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_512_194"
          x1="12.4199"
          y1="18.6508"
          x2="5.72557"
          y2="7.16707"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" stopOpacity={0} />
          <stop offset={1} stopColor="#FBED21" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_512_194"
          x1="4.17411"
          y1="14.4391"
          x2="4.17411"
          y2="18.9498"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset={1} stopColor="#E4F2FF" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_512_194"
          x1="10.7814"
          y1="14.4392"
          x2="10.7814"
          y2="18.9499"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset={1} stopColor="#E4F2FF" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_512_194"
          x1="17.3894"
          y1="14.4392"
          x2="17.3894"
          y2="18.9499"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
          <stop offset={1} stopColor="#E4F2FF" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_512_194"
          x1="4.17411"
          y1="12.7033"
          x2="4.17411"
          y2="11.2817"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_512_194"
          x1="10.7815"
          y1="12.7032"
          x2="10.7815"
          y2="11.2816"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_512_194"
          x1="17.3894"
          y1="12.7034"
          x2="17.3894"
          y2="11.2818"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_512_194"
          x1="4.38421"
          y1="15.861"
          x2="1.79191"
          y2="13.2075"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_512_194"
          x1="2.27393"
          y1="16.0421"
          x2="6.0739"
          y2="16.0421"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_512_194"
          x1="2.45215"
          y1="16.0422"
          x2="5.89628"
          y2="16.0422"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_512_194"
          x1="11.012"
          y1="15.8813"
          x2="8.3966"
          y2="13.2042"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_512_194"
          x1="12.4191"
          y1="17.0805"
          x2="9.3866"
          y2="15.1812"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_512_194"
          x1="8.88184"
          y1="16.0421"
          x2="12.6818"
          y2="16.0421"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_512_194"
          x1="9.05957"
          y1="16.0422"
          x2="12.5037"
          y2="16.0422"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_512_194"
          x1="17.536"
          y1="15.6938"
          x2="14.9499"
          y2="12.5681"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_512_194"
          x1="18.9587"
          y1="16.9865"
          x2="16.1119"
          y2="15.2035"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_512_194"
          x1="15.4893"
          y1="16.0421"
          x2="19.2892"
          y2="16.0421"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_512_194"
          x1="15.667"
          y1="16.0422"
          x2="19.1111"
          y2="16.0422"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF7044" />
          <stop offset={1} stopColor="#F82814" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_512_194"
          x1="5.81582"
          y1="17.0319"
          x2="2.82174"
          y2="15.1567"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3F3CED" stopOpacity={0} />
          <stop offset="0.2796" stopColor="#3C3DE4" stopOpacity="0.28" />
          <stop offset="0.7305" stopColor="#3541CC" stopOpacity="0.731" />
          <stop offset={1} stopColor="#2F43BB" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_512_194"
          x1="10.6889"
          y1="2.57613"
          x2="11.5087"
          y2="2.57613"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_512_194"
          x1="14.406"
          y1="5.55416"
          x2="14.406"
          y2="6.37398"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_512_194"
          x1="7.15743"
          y1="5.55416"
          x2="7.15743"
          y2="6.37398"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_512_194"
          x1="13.202"
          y1="3.41464"
          x2="13.7816"
          y2="3.99429"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_512_194"
          x1="8.35235"
          y1="3.40495"
          x2="7.7727"
          y2="3.98459"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD945" />
          <stop offset="0.3043" stopColor="#FFCD3E" />
          <stop offset="0.8558" stopColor="#FFAD2B" />
          <stop offset={1} stopColor="#FFA325" />
        </linearGradient>
        <clipPath id="clip0_512_194">
          <rect width={26} height={26} fill="white" transform="translate(0.078125)" />
        </clipPath>
      </defs>
    </svg>
  );
}
