export const BREAKPOINT = {
  mobile: 567,
  table: 768,
  desktop: 1366
};
export const REGEX = {
  PASSWORD: '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,30}$'
};

export const BYBET_ACCOUNT = 'bybet-account';

export const EXPIRED_TIME = 30 * 24 * 60 * 60;

export const STATE_TRANSACTION_CONVERT: any = {
  INVALID: 'Fail',
  VALIDATING: 'Pending',
  COMPLETED: 'Done',
  PROCESSING: 'Pending'
};
export const PAGE_SIZE = 10;

export const STAKING_DATES = [
  { key: '2Weeks', label: '2 Weeks', value: 60 * 60 * 24 * 14 },
  { key: '1Month', label: '1 Month', value: 60 * 60 * 24 * 30 },
  { key: '6Months', label: '6 Months', value: 60 * 60 * 24 * 30 * 6 },
  { key: '1Year', label: '1 Year', value: 60 * 60 * 24 * 365 },
  { key: '2Years', label: '2 Years', value: 60 * 60 * 24 * 365 * 2 },
  { key: '4Years', label: '4 Years', value: 60 * 60 * 24 * 365 * 4 }
];

export enum SLIDE_USER {
  MAIN = 0,
  DETAIL = 1,
  EDIT_NAME = 2,
  EDIT_AVATAR = 3
}
export const MAX_BETTING_TOKEN = 100000;
export const RING_TIME_SECONDS = 4;
