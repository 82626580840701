import React from 'react';

export default function LockIcon() {
  return (
    <svg width={28} height={36} viewBox="0 0 28 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.8926 14.9926H23.6426V9.66891C23.6453 7.10826 22.6311 4.65133 20.8229 2.83822C19.0147 1.02511 16.5605 0.00422883 13.9999 0C8.6702 0 4.35714 4.34038 4.35714 9.6751V14.9926H2.11129C1.73827 14.9919 1.37985 15.1375 1.11295 15.3981C0.846046 15.6587 0.691947 16.0135 0.683716 16.3865V32.5188C0.692937 33.4448 1.06712 34.3298 1.72501 34.9816C2.38289 35.6334 3.27136 35.9994 4.19746 36H23.8066C24.7323 35.9992 25.6202 35.633 26.2773 34.9811C26.9344 34.3292 27.3077 33.4443 27.316 32.5188V16.3865C27.3087 16.0139 27.1555 15.6592 26.8893 15.3985C26.6231 15.1378 26.2652 14.9921 25.8926 14.9926ZM7.11221 9.6751C7.1118 8.7706 7.28955 7.87487 7.63531 7.03906C7.98107 6.20326 8.48807 5.44375 9.12736 4.80388C9.76665 4.16401 10.5257 3.65633 11.3612 3.30981C12.1967 2.9633 13.0923 2.78473 13.9968 2.78433C14.9013 2.78392 15.797 2.96167 16.6328 3.30743C17.4686 3.65319 18.2281 4.16022 18.868 4.79951C19.5079 5.4388 20.0155 6.19785 20.3621 7.03335C20.7086 7.86884 20.8871 8.76441 20.8875 9.66891V14.9926H7.11221V9.6751ZM24.4462 32.5192C24.4462 32.9027 24.1901 33.2454 23.8066 33.2454H4.19746C3.81393 33.2454 3.55358 32.9027 3.55358 32.5192V17.7477H24.4462V32.5192Z"
        fill="#A2A3A4"
      />
      <path
        d="M13.9998 29.6678C14.1817 29.6676 14.3618 29.6314 14.5297 29.5612C14.6976 29.4911 14.8499 29.3884 14.9779 29.2591C15.106 29.1299 15.2072 28.9766 15.2757 28.808C15.3443 28.6395 15.3788 28.459 15.3773 28.2771V23.2706C15.3773 22.9053 15.2322 22.5549 14.9738 22.2965C14.7155 22.0382 14.3651 21.8931 13.9998 21.8931C13.6344 21.8931 13.284 22.0382 13.0257 22.2965C12.7674 22.5549 12.6222 22.9053 12.6222 23.2706V28.2771C12.6207 28.459 12.6552 28.6395 12.7238 28.8081C12.7923 28.9766 12.8935 29.1299 13.0216 29.2592C13.1496 29.3885 13.3019 29.4911 13.4698 29.5613C13.6377 29.6314 13.8178 29.6676 13.9998 29.6678Z"
        fill="#A2A3A4"
      />
    </svg>
  );
}
