import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/layouts';
import { routes } from './routes';
import { setAccountInfo, setLoggedIn } from './stores/AccountSlice';
import cookie from 'cookie';
import 'swiper/css';
import './styles/global.scss';
import './styles/date-picker.scss';
import './styles/swiper.scss';
import { storageService } from './services/storage';
import 'react-datepicker/dist/react-datepicker.css';
import 'swiper/css/effect-creative';

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    const accountData = storageService.getAccount();
    accountData && dispatch(setAccountInfo(accountData));
  }, []);

  useEffect(() => {
    const cookies = cookie.parse(window.document.cookie);
    dispatch(setLoggedIn(!!cookies.token));
  }, [cookie]);
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          {routes.map((route) => (
            <Route key={route.path} path={route.path} element={route.element} />
          ))}
        </Routes>
      </Layout>
    </BrowserRouter>
  );
}

export default App;
