import React from 'react';

interface PropsType {
  active?: boolean;
}

const GameShow = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.7933 1.28099C16.58 0.982874 16.1597 0.910429 15.8553 1.11942L14.171 2.27239C13.8823 2.46997 13.8123 2.85897 14.0143 3.14129C14.5918 3.94784 14.4005 5.08588 13.5649 5.63514C12.7549 6.16728 11.6548 5.9697 11.0922 5.18467C10.8789 4.88655 10.4591 4.81454 10.1542 5.02309L5 8.55181H15.633C16.747 8.55181 17.6536 9.43827 17.6536 10.5276V10.5609C17.6536 10.8029 17.8552 11 18.1026 11C18.35 11 18.5516 10.8029 18.5516 10.5609V10.4929C18.5516 9.42246 19.442 8.55181 20.5367 8.55181H22L16.7933 1.28099Z"
        fill={active ? '#FFE036' : '#7F7F7F'}
      />
      <path
        d="M22.3555 10H20.3879C20.0506 10 19.7773 10.2667 19.7773 10.5959C19.7773 11.3021 19.3326 11.9223 18.7031 12.1798V13.1452C18.7031 13.4924 18.4144 13.7742 18.0586 13.7742C17.7028 13.7742 17.4141 13.4924 17.4141 13.1452V12.184C16.7841 11.9353 16.3398 11.3327 16.3398 10.629C16.3398 10.2818 16.0511 10 15.6953 10H1.64453C1.28875 10 1 10.2818 1 10.629V22.371C1 22.7182 1.28875 23 1.64453 23H15.6953C16.0511 23 16.3398 22.7182 16.3398 22.371C16.3398 21.6669 16.7841 21.0647 17.4141 20.816V19.8548C17.4141 19.5076 17.7028 19.2258 18.0586 19.2258C18.4144 19.2258 18.7031 19.5076 18.7031 19.8548V20.816C19.333 21.0647 19.7773 21.6673 19.7773 22.371C19.7773 22.7182 20.0661 23 20.4219 23H22.3555C22.7113 23 23 22.7182 23 22.371V10.629C23 10.2818 22.7113 10 22.3555 10ZM13.1095 16.0538L11.6683 17.4247C11.542 17.545 11.4839 17.7186 11.514 17.8885L11.8543 19.8242C11.9295 20.2524 11.4693 20.5786 11.0753 20.3765L9.29426 19.4627C9.13785 19.3826 8.95094 19.3826 8.79453 19.4627L7.01348 20.3765C6.61945 20.5786 6.15926 20.2524 6.23445 19.8242L6.57477 17.8885C6.60484 17.7186 6.54684 17.545 6.42051 17.4247L4.97934 16.0538C4.66051 15.7506 4.83668 15.2226 5.27668 15.1602L7.26828 14.8779C7.44316 14.8532 7.59441 14.7458 7.67262 14.5915L8.56336 12.8302C8.76016 12.4406 9.32906 12.4406 9.52629 12.8302L10.417 14.5915C10.4952 14.7463 10.6465 14.8532 10.8214 14.8779L12.813 15.1602C13.2521 15.2226 13.4283 15.7506 13.1095 16.0538ZM18.7031 17.3387C18.7031 17.6859 18.4144 17.9677 18.0586 17.9677C17.7028 17.9677 17.4141 17.6859 17.4141 17.3387V15.6613C17.4141 15.3141 17.7028 15.0323 18.0586 15.0323C18.4144 15.0323 18.7031 15.3141 18.7031 15.6613V17.3387Z"
        fill={active ? '#FFE036' : '#7F7F7F'}
      />
    </svg>
  );
};

export default GameShow;
