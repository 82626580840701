import { useMemo } from 'react';
import { ETHER_PROVIDER, getErc20Contract, getStakeContract } from '../lib/contract-accessor';
import useActiveWeb3React from './useWeb3';

export const useErc20 = (address: string, withSigner = false as boolean) => {
  const { library } = useActiveWeb3React();
  if (withSigner) {
    return useMemo(() => getErc20Contract(address, library?.getSigner()), [address, library]);
  }
  return useMemo(() => getErc20Contract(address, ETHER_PROVIDER), [address]);
};

export const useStakeBBet = (address: string, withSigner = false as boolean) => {
  const { library } = useActiveWeb3React();
  if (withSigner) {
    return useMemo(() => getStakeContract(address, library?.getSigner()), [address, library]);
  }
  return useMemo(() => getStakeContract(address, ETHER_PROVIDER), [address]);
};
