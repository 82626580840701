import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { injected } from '../lib/connectors';

export function useInactiveListener(suppress: boolean = false) {
  const { active, error, activate } = useWeb3React();

  useEffect((): any => {
    const { ethereum } = window as any;
    if (ethereum && ethereum.on && !error && !suppress) {
      const handleConnect = () => {
        console.log("Handling 'connect' event");
        activate(injected);
      };
      const handleChainChanged = (chainId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", chainId);
        activate(injected);
      };
      const handleAccountsChanged = (accounts: string[]) => {
        console.log("Handling 'accountsChanged' event with payload", accounts);
        if (accounts.length > 0) {
          activate(injected);
        }
      };
      const handleNetworkChanged = (networkId: string | number) => {
        console.log("Handling 'chainChanged' event with payload", networkId);
        activate(injected);
      };

      const handleNetworkDisconnect = async (networkId: string | number) => {
        console.log("Handling 'disconnect' event with payload", networkId);
      };

      ethereum.on('connect', handleConnect);
      ethereum.on('chainChanged', handleChainChanged);
      ethereum.on('accountsChanged', handleAccountsChanged);
      ethereum.on('chainChanged', handleNetworkChanged);
      ethereum.on('disconnect', handleNetworkChanged);

      return () => {
        if (ethereum.removeListener) {
          ethereum.removeListener('connect', handleConnect);
          ethereum.removeListener('chainChanged', handleChainChanged);
          ethereum.removeListener('accountsChanged', handleAccountsChanged);
          ethereum.removeListener('chainChanged', handleNetworkChanged);
          ethereum.removeListener('"disconnect"', handleNetworkDisconnect);
        }
      };
    }
  }, [error, activate, active, suppress]);
}
