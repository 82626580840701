import styles from '../../styles/UI/table.module.scss';
import classnames from 'classnames/bind';
import { Columns } from '../../interfaces/Table';
import Pagination from './Pagination';

const cx = classnames.bind(styles);

interface PropsType {
  columns: Columns[];
  dataTable: any[];
  pagination: {
    page: number;
    pageSize: number;
    total: number;
  };
  changePage?: (page: number) => void;
}

export default function Table({ columns, dataTable, pagination, changePage }: PropsType) {
  return (
    <div>
      <table className={cx('table-container')}>
        <thead>
          <tr>
            {columns.map((col) => (
              <th key={col.key} className={cx('table-header-item')}>
                {col.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dataTable.length > 0 ? (
            dataTable.map((data: any, index) => {
              return (
                <tr key={index}>
                  {columns.map((col) => (
                    <td key={col.key + index} className={cx('data-item')}>
                      {col.render ? col.render(data[col.key]) : data[col.key]}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr className={cx('nodata')}>
              <td colSpan={columns.length} className={cx('nodata-item')}>
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {changePage && dataTable.length > 0 && (
        <div className={cx('pagination-container')}>
          <Pagination pagination={pagination} changePage={changePage} />
        </div>
      )}
    </div>
  );
}
