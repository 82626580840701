import { getEnv } from '../utils';

export const normalizeSlashUrl = (baseURL = '', url: string) => {
  const normalizedBaseUrl = baseURL.endsWith('/') ? baseURL.slice(0, baseURL.length - 1) : baseURL;
  const normalizedPath = url.startsWith('/') ? url.slice(1) : url;
  return `${normalizedBaseUrl}/${normalizedPath}`;
};

class Api {
  baseURL: string;
  interceptors: Array<(request: RequestInit) => void>;
  defaultConfig: any;

  constructor(url: string, defaultConfig: any = {}) {
    this.baseURL = url;
    this.interceptors = [];
    this.defaultConfig = defaultConfig;
  }

  appendParamsToURL(url: string, params: any = {}) {
    if (params) {
      const paramsArr = [];
      for (const key of Object.keys(params)) {
        if (params[key] !== null && params[key] !== undefined) {
          paramsArr.push(`${key}=${encodeURIComponent(params[key])}`);
        }
      }
      if (paramsArr.length > 0) url = `${url}?${paramsArr.join('&')}`;
    }
    return url;
  }

  async request(url: string, body: any, method: string, config: any = {}) {
    const headers = config.headers || {};
    url = this.appendParamsToURL(url, config.params);
    // if (config.url) {
    //   url=
    // }
    if (body) {
      if (typeof body !== 'string') body = JSON.stringify(body);
    }

    const requestObj: RequestInit = {
      ...this.defaultConfig,
      method: method,
      headers: new Headers({
        'Content-Type': 'application/json',
        ...headers
      }),
      body
    };

    this.interceptors.forEach((fn) => fn(requestObj));
    const res = await fetch(
      url.startsWith('http') ? url : normalizeSlashUrl(this.baseURL, url),
      requestObj
    );
    if (res.status >= 400) {
      // sync the original axios reject a promise
      // on error, throw Error here to emulate it
      return Promise.reject(await res.json());
    }

    const data = await res.json();
    return data;
  }

  async get(url: string, config: any = {}) {
    return await this.request(url, null, 'GET', config);
  }

  async post(url: string, body: any, config: any = {}) {
    return await this.request(url, body, 'POST', config);
  }

  async patch(url: string, body: any, config: any = {}) {
    return await this.request(url, body, 'PATCH', config);
  }

  async put(url: string, body: any, config: any = {}) {
    return await this.request(url, body, 'PUT', config);
  }

  async delete(url: string, config: any = {}) {
    return await this.request(url, null, 'DELETE', config);
  }
}

export const bybetApi = new Api(getEnv('REACT_APP_BYBET_BASE_URL', ''));
export const stakingApi = new Api(getEnv('REACT_APP_STAKING_BASE_URL', ''));
