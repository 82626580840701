import classNames from 'classnames/bind';
import React from 'react';
import { topWining } from '../../constant/data-home-page';
import styles from '../../styles/components/home/top-wining.module.scss';
import { convertToCurrency } from '../../utils/convertCurrency';
import NextLoadIcon from '../Icons/NextLoadIcon';

const cx = classNames.bind(styles);

const TopWining = () => {
  return (
    <div className={cx('wrapper')}>
      <div className={cx('title-component')}>Top wining</div>
      <ul className={cx('list')}>
        {topWining.map((item) => {
          return (
            <li key={item.top} className={cx('item')}>
              <div className={cx('desc')}>
                <div className={cx('item-img')}>
                  <img src={`/images/top-wining/item${item.top}.png`} alt="image" />
                </div>

                <div className={cx('content')}>
                  <div className={cx('price')}>Profit: ${convertToCurrency(item.profit)}</div>
                  <div className={cx('desc')}>In {item.game}</div>
                </div>
              </div>

              <div className={cx('icon')}>
                <NextLoadIcon />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TopWining;
