import classNames from 'classnames/bind';
import React from 'react';
import { listGame } from '../../constant/data-home-page';
import styles from '../../styles/components/home/list-game.module.scss';
import ComingSoon from '../Icons/ComingSoon';
import QuestionIcon from '../Icons/QuestionIcon';

const cx = classNames.bind(styles);

const ListGame = () => {
  return (
    <div className={cx('wrapper')}>
      <ul className={cx('list-game')}>
        {listGame.map((item) => {
          return (
            <li className={cx('item')} key={item.key}>
              <div className={cx('item-img', 'img-disable')}>
                <img
                  className={cx(item.isComing && 'img-disable')}
                  src={`/images/list-game/${item.key}.png`}
                  alt={item.key}
                />
              </div>
              <div className={cx('item-desc')}>
                <div>{item.value}</div>
                <div>
                  <QuestionIcon />
                </div>
              </div>
              {item.isComing && (
                <div className={cx('coming-soon')}>
                  <ComingSoon />
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ListGame;
