import React from 'react';
interface PropsType {
  active?: boolean;
}
const LiveCasinoIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_610_2045)">
        <path
          d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9964 8.8185 22.731 5.76833 20.4813 3.51867C18.2317 1.269 15.1815 0.00357316 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.76121C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.997 14.6512 20.9425 17.193 19.0677 19.0677C17.193 20.9425 14.6512 21.997 12 22V22Z"
          fill={active ? '#FFE036' : '#7F7F7F'}
        />
        <path
          d="M12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C20.9973 9.61387 20.0483 7.32625 18.361 5.639C16.6738 3.95175 14.3861 3.00268 12 3ZM12 8C12.1978 8 12.3911 8.05865 12.5556 8.16853C12.72 8.27841 12.8482 8.43459 12.9239 8.61732C12.9996 8.80004 13.0194 9.00111 12.9808 9.19509C12.9422 9.38907 12.847 9.56725 12.7071 9.70711C12.5673 9.84696 12.3891 9.9422 12.1951 9.98079C12.0011 10.0194 11.8 9.99957 11.6173 9.92388C11.4346 9.84819 11.2784 9.72002 11.1685 9.55557C11.0587 9.39112 11 9.19778 11 9C11 8.73478 11.1054 8.48043 11.2929 8.29289C11.4804 8.10536 11.7348 8 12 8V8ZM6 10C6 9.80222 6.05865 9.60888 6.16853 9.44443C6.27842 9.27998 6.43459 9.15181 6.61732 9.07612C6.80005 9.00043 7.00111 8.98063 7.19509 9.01921C7.38908 9.0578 7.56726 9.15304 7.70711 9.29289C7.84696 9.43275 7.9422 9.61093 7.98079 9.80491C8.01937 9.99889 7.99957 10.2 7.92388 10.3827C7.8482 10.5654 7.72002 10.7216 7.55557 10.8315C7.39113 10.9414 7.19779 11 7 11C6.73479 11 6.48043 10.8946 6.2929 10.7071C6.10536 10.5196 6 10.2652 6 10ZM16 16H8L7.375 11.375L10 13L12 10L14 13L16.5 11.375L16 16ZM17 11C16.8022 11 16.6089 10.9414 16.4444 10.8315C16.28 10.7216 16.1518 10.5654 16.0761 10.3827C16.0004 10.2 15.9806 9.99889 16.0192 9.80491C16.0578 9.61093 16.153 9.43275 16.2929 9.29289C16.4327 9.15304 16.6109 9.0578 16.8049 9.01921C16.9989 8.98063 17.2 9.00043 17.3827 9.07612C17.5654 9.15181 17.7216 9.27998 17.8315 9.44443C17.9414 9.60888 18 9.80222 18 10C18 10.2652 17.8946 10.5196 17.7071 10.7071C17.5196 10.8946 17.2652 11 17 11Z"
          fill={active ? '#FFE036' : '#7F7F7F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_610_2045">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LiveCasinoIcon;
