import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { PAGE_SIZE } from '../../../constant';
import { TOKENS } from '../../../constant/token';
import { shortenAddress } from '../../../lib/shortenAddress';
import { stakingService } from '../../../services/staking';
import CopyIcon from '../../Icons/CopyIcon';
import Table from '../../UI/Table';
import styles from '../../../styles/Staking/history.module.scss';
import classnames from 'classnames/bind';
import { toast } from 'react-toastify';

const pageSize = PAGE_SIZE;

const cx = classnames.bind(styles);

export default function StakingHistory() {
  const context = useWeb3React<Web3Provider>();
  const { account } = context;
  const [page, setPage] = useState(1);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  // const loadingStaking = useSelector((state: RootState) => state.loading.loading.staking);

  const columns = [
    { key: 'lockedDate', title: 'Locked Date' },
    { key: 'unlockDate', title: 'Unlock Date' },
    { key: 'lockingPeriod', title: 'Locking Period' },
    { key: 'bbet', title: `$ ${TOKENS.BBET}` },
    { key: 'xbt', title: `$ ${TOKENS.XBT}` },
    {
      key: 'hash',
      title: 'Transaction Hash',
      render: (value: string) => (
        <p className={cx('hash-text')}>
          <span>{shortenAddress(value)}</span>
          <button
            className={cx('btn-copy')}
            onClick={() => {
              handleCopy(value);
            }}>
            <CopyIcon width={17} height={16} />
          </button>
        </p>
      )
    }
  ];

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success('Copied!');
  };

  const fetchData = async () => {
    if (account) {
      try {
        const params = {
          account: account.toLowerCase(),
          type: 'staking',
          limit: pageSize,
          skip: (page - 1) * pageSize
        };
        const res = await stakingService.getHistoryTransaction(params);

        if (res) {
          setTotal(res.count);
          const data = res.data?.map((item: any) => {
            return {
              lockedDate: moment(
                new BigNumber(item.stakingAt).multipliedBy(1000).toNumber()
              ).format('YYYY-MM-DD HH:mm:ss'),
              unlockDate: moment(new BigNumber(item.unlockAt).multipliedBy(1000).toNumber()).format(
                'YYYY-MM-DD HH:mm:ss'
              ),
              lockingPeriod: `${new BigNumber(item.unlockAt)
                .minus(item.stakingAt)
                .div(60 * 60 * 24)
                .toFormat(0)} days`,
              bbet: new BigNumber(item.amount).div(1e18).toFormat(2),
              xbt: new BigNumber(item.amountX).div(1e18).toFormat(2),
              hash: item.transactionHash
            };
          });
          setDataTable(data);
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const changePage = (page: number) => {
    setPage(page);
  };

  // useEffect(() => {
  //   if (!loadingStaking && page === 1) {
  //     fetchData();
  //   } else if (!loadingStaking) {
  //     setPage(1);
  //   }
  // }, [loadingStaking]);

  useEffect(() => {
    fetchData();
  }, [account, page]);

  return (
    <Table
      columns={columns}
      dataTable={dataTable}
      pagination={{ page, pageSize, total }}
      changePage={changePage}
    />
  );
}
