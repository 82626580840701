import React from 'react';

const ComingSoon = () => {
  return (
    <svg width={63} height={63} viewBox="0 0 63 63" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1144_25765)">
        <path
          d="M60.8865 62.5659C61.2978 62.3831 61.6063 61.9774 61.572 61.5261C61.5549 61.269 61.4406 61.0176 61.2407 60.8234L59 58.5C59 60.5 59 60.5 59 61.5261L59 62.9944H59.961L60.8865 62.5659Z"
          fill="url(#paint0_linear_1144_25765)"
        />
        <path
          d="M2.03959 2.06237C0.5 0.5 0.0744122 1.36536 0.00585938 2.06237C0.00585938 2.0738 0 3.99985 0 3.99985H4C2.5 2.49985 2.03959 2.06237 2.03959 2.06237Z"
          fill="url(#paint1_linear_1144_25765)"
        />
        <path
          d="M62.9886 60.3605C62.9943 53.3732 63 34.9022 63 34.9022C63 32.9312 62.2174 31.0458 60.8235 29.6518L33.3566 2.17675C31.9627 0.782715 30.0718 0 28.1009 0C28.1009 0 6.48966 0 1.17111 0.00571325C0.525571 0.00571325 0 0.531332 0 1.17693V2.20531C0 2.19389 0.00571273 2.08534 0.00571273 2.07391C0.25136 0.868414 1.36534 1.41117 1.87378 1.8968L11.6254 11.615L11.6197 11.6265C24.839 24.8469 38.0582 38.0674 51.2775 51.2878L61.2462 60.829C61.4461 61.0232 61.5547 61.2746 61.5775 61.5317C61.6118 61.983 61.3033 62.3887 60.892 62.5715L59.9665 63H60.355C61.806 63 62.9886 61.8231 62.9886 60.3605Z"
          fill="url(#paint2_linear_1144_25765)"
        />
        <path
          d="M28.1589 9.17237C27.9111 8.91913 27.7225 8.6605 27.5878 8.39648C27.4531 8.13246 27.3831 7.86844 27.3831 7.60981C27.3777 7.35117 27.4478 7.10332 27.5824 6.86624C27.7171 6.62916 27.9326 6.41363 28.2236 6.20888C28.4014 6.08496 28.5953 5.96103 28.8108 5.82633C29.021 5.69701 29.2257 5.56769 29.425 5.44915C29.8291 5.21208 30.2278 5.07198 30.6211 5.0181C31.0144 4.96961 31.3969 5.0181 31.774 5.16897C32.1458 5.31984 32.5068 5.57308 32.8516 5.92331C33.0833 6.16039 33.2718 6.40825 33.4119 6.67227C33.552 6.93629 33.6328 7.19492 33.6543 7.45355C33.6759 7.71218 33.6382 7.95465 33.5358 8.18095C33.4281 8.41264 33.2503 8.612 33.0024 8.77904C32.9593 8.80598 32.9162 8.81675 32.8731 8.81136C32.8246 8.81136 32.7869 8.78981 32.7546 8.75748L32.0111 7.99775C31.9626 7.94926 31.9357 7.90077 31.9357 7.85766C31.9303 7.81456 31.9572 7.76067 32.0003 7.7014C32.1997 7.51821 32.2805 7.33501 32.2536 7.1572C32.2266 6.97939 32.1296 6.80697 31.9626 6.63455C31.7633 6.4298 31.5316 6.31126 31.273 6.27354C31.0144 6.23583 30.7235 6.31665 30.3948 6.51062C30.0123 6.73154 29.6351 6.96861 29.2634 7.22725C28.9563 7.44277 28.7893 7.64752 28.7677 7.85227C28.7462 8.05702 28.8324 8.25638 29.0317 8.46113C29.2041 8.63356 29.3927 8.75748 29.5974 8.81675C29.8022 8.88141 30.05 8.85986 30.3302 8.76287C30.4164 8.73593 30.481 8.73054 30.5295 8.74132C30.578 8.75209 30.6265 8.77904 30.675 8.82753L31.4185 9.58726C31.4508 9.61959 31.467 9.6573 31.4616 9.70041C31.4562 9.73813 31.4346 9.77584 31.3969 9.7974C31.1383 9.94827 30.8743 10.0506 30.5995 10.1045C30.3248 10.1584 30.05 10.1584 29.7752 10.1099C29.5005 10.0614 29.2257 9.95904 28.9563 9.80817C28.6815 9.64653 28.4175 9.43639 28.1589 9.17237Z"
          fill="white"
        />
        <path
          d="M31.9786 13.073C31.747 12.8413 31.5692 12.5934 31.4399 12.3294C31.3052 12.0708 31.2351 11.8067 31.2298 11.5427C31.219 11.2787 31.2782 11.0255 31.4129 10.783C31.5476 10.5405 31.7577 10.3142 32.0541 10.1095C32.2426 9.98015 32.4366 9.85622 32.6305 9.73229C32.8299 9.60837 33.0238 9.48983 33.2178 9.3659C33.8374 8.9995 34.4354 8.84325 35.0119 8.91329C35.583 8.97795 36.1379 9.27969 36.6659 9.82389C36.8976 10.0556 37.0754 10.3034 37.2047 10.5675C37.334 10.8315 37.404 11.0955 37.4094 11.3595C37.4148 11.6235 37.3501 11.8822 37.2208 12.1246C37.0862 12.3671 36.8706 12.5934 36.5743 12.7928C36.3911 12.9221 36.1972 13.0514 36.0032 13.1753C35.8093 13.2993 35.6099 13.4178 35.416 13.5363C34.7856 13.9081 34.1876 14.059 33.6219 13.9835C33.0508 13.9189 32.5066 13.6118 31.9786 13.073ZM32.8568 12.3671C33.0562 12.5665 33.2878 12.685 33.5518 12.7173C33.8158 12.7497 34.1122 12.6742 34.4354 12.4803C34.6402 12.3617 34.8287 12.2432 35.0065 12.1354C35.1843 12.0276 35.3621 11.9091 35.5399 11.7798C35.847 11.575 36.014 11.3649 36.0409 11.1548C36.0679 10.9446 35.9871 10.7399 35.7877 10.5405C35.5884 10.3412 35.3621 10.2226 35.0927 10.1903C34.8287 10.158 34.5324 10.2334 34.2091 10.4274C34.0152 10.5405 33.8266 10.6483 33.6488 10.7614C33.471 10.8692 33.2878 10.9931 33.0993 11.1224C32.7976 11.3326 32.6252 11.5427 32.5982 11.7475C32.5659 11.9522 32.6521 12.157 32.8568 12.3671Z"
          fill="white"
        />
        <path
          d="M34.4304 15.4656C34.3926 15.4225 34.3765 15.3794 34.3819 15.3309C34.3873 15.2824 34.4142 15.2447 34.4573 15.2124L38.8321 12.4321C38.8806 12.3998 38.9345 12.389 38.9937 12.3944C39.053 12.3998 39.1015 12.4267 39.1392 12.4698L39.7264 13.0733C39.7857 13.1326 39.8073 13.1918 39.8019 13.2457C39.7911 13.2996 39.7857 13.3427 39.7749 13.3696L38.6166 15.6973L41.6983 15.3309C41.7253 15.3255 41.7737 15.3202 41.8438 15.3255C41.9084 15.3309 41.9731 15.3633 42.0324 15.4225L42.625 16.0314C42.6627 16.0745 42.6843 16.1176 42.6789 16.1607C42.6735 16.2092 42.6466 16.2469 42.5981 16.2793L38.2233 19.0595C38.1802 19.0865 38.1263 19.1026 38.067 19.0973C38.0078 19.0919 37.9593 19.0703 37.9162 19.0326L37.2535 18.3591C37.2158 18.3214 37.205 18.2783 37.2104 18.2298C37.2158 18.1813 37.2427 18.1436 37.2858 18.1112L39.7695 16.5325L37.803 16.7857C37.7492 16.7911 37.6899 16.7857 37.6252 16.7803C37.5606 16.775 37.5067 16.7426 37.4528 16.6888L37.1673 16.3978C37.1188 16.3439 37.0919 16.2954 37.0973 16.2469C37.1026 16.1984 37.1134 16.1553 37.135 16.1122L37.927 14.5604L35.384 16.1769C35.3409 16.2038 35.287 16.2146 35.2277 16.2092C35.1685 16.2038 35.12 16.1823 35.0823 16.1445L34.4304 15.4656Z"
          fill="white"
        />
        <path
          d="M38.945 20.0784C38.9073 20.0353 38.8911 19.9922 38.8965 19.9437C38.9019 19.8952 38.9288 19.8575 38.9719 19.8252L43.3521 17.0556C43.4006 17.0233 43.4545 17.0125 43.5137 17.0179C43.573 17.0233 43.6215 17.0503 43.6592 17.088L44.3973 17.8423C44.435 17.88 44.4512 17.9231 44.4458 17.9716C44.4404 18.0201 44.4135 18.0578 44.365 18.0956L39.9902 20.8758C39.9471 20.9028 39.8932 20.9136 39.834 20.9082C39.7747 20.9028 39.7262 20.8812 39.6885 20.8435L38.945 20.0784Z"
          fill="white"
        />
        <path
          d="M40.7119 21.8826C40.6742 21.8449 40.6634 21.8018 40.6688 21.7533C40.6742 21.7048 40.7011 21.6671 40.7442 21.6348L45.119 18.8545C45.1675 18.8221 45.2214 18.8114 45.2752 18.8168C45.3291 18.8221 45.3776 18.8491 45.4207 18.8868L45.9972 19.4795C46.0565 19.5388 46.0834 19.5926 46.078 19.6411C46.0726 19.6896 46.0672 19.7274 46.0564 19.7489L44.7903 22.7717L47.3549 21.1444C47.4034 21.1121 47.4572 21.1013 47.5165 21.1067C47.5758 21.1121 47.6243 21.139 47.6566 21.1768L48.3139 21.8449C48.3516 21.888 48.3731 21.9311 48.3678 21.9796C48.3624 22.0281 48.3354 22.0658 48.2869 22.1035L43.9122 24.873C43.8637 24.9054 43.8098 24.9215 43.7505 24.9161C43.6913 24.9108 43.6428 24.8892 43.5997 24.8461L43.0232 24.2534C42.9639 24.1941 42.937 24.1403 42.9478 24.0918C42.9532 24.0433 42.9585 24.0055 42.9693 23.984L44.1492 21.0259L41.6763 22.5939C41.6332 22.6208 41.5793 22.6316 41.52 22.6262C41.4608 22.6208 41.4069 22.5939 41.3692 22.5561L40.7119 21.8826Z"
          fill="white"
        />
        <path
          d="M46.0889 27.487C45.7441 27.1314 45.5178 26.7865 45.4101 26.4417C45.3023 26.0968 45.3185 25.7682 45.4532 25.4556C45.5879 25.1431 45.8411 24.8576 46.2236 24.5935C46.4122 24.4696 46.6169 24.3349 46.8324 24.1948C47.0479 24.0547 47.258 23.9254 47.4574 23.8069C47.8507 23.5752 48.2494 23.4459 48.6535 23.4189C49.0575 23.392 49.4562 23.4674 49.8441 23.6344C50.232 23.8015 50.5984 24.0601 50.9378 24.4049C51.2126 24.6851 51.4119 24.9545 51.5359 25.2239C51.6652 25.4934 51.7298 25.7412 51.7352 25.9783C51.7406 26.2154 51.6975 26.4255 51.6059 26.6087C51.5143 26.7919 51.3904 26.9374 51.2395 27.0451C51.1964 27.0721 51.1533 27.0775 51.1048 27.0721C51.0564 27.0667 51.0133 27.0451 50.9755 27.0074L50.1782 26.1938C50.1404 26.1561 50.1189 26.1238 50.1135 26.0914C50.1081 26.0591 50.1135 26.0268 50.1297 25.9891C50.1728 25.9244 50.2105 25.8436 50.232 25.7466C50.259 25.6496 50.2536 25.5418 50.2213 25.4179C50.1889 25.294 50.1027 25.1647 49.968 25.0246C49.7579 24.8144 49.5316 24.6905 49.2784 24.6528C49.0252 24.6151 48.745 24.6851 48.4379 24.8629C48.244 24.9761 48.05 25.1 47.8507 25.2239C47.6513 25.3479 47.4574 25.4772 47.2634 25.6065C46.9617 25.8166 46.8001 26.0322 46.7839 26.2423C46.7678 26.4578 46.8648 26.6734 47.0749 26.8835C47.2096 27.0236 47.3604 27.126 47.5274 27.2014C47.6944 27.2768 47.8722 27.3038 48.0554 27.2876C48.2386 27.2714 48.4272 27.2014 48.6157 27.0775L48.745 26.9967L48.2332 26.474C48.1955 26.4309 48.1793 26.3878 48.1847 26.3393C48.1901 26.2908 48.2224 26.2531 48.2655 26.2208L48.7666 25.9082C48.8151 25.8759 48.869 25.8651 48.9282 25.8705C48.9875 25.8759 49.036 25.9029 49.0791 25.9406L50.4529 27.3469C50.4906 27.39 50.5068 27.4331 50.5014 27.4816C50.4906 27.5301 50.4637 27.5678 50.4152 27.6001L49.6502 28.0904C49.2623 28.3383 48.8636 28.473 48.4487 28.4999C48.0285 28.5269 47.619 28.4515 47.2149 28.279C46.8109 28.1012 46.4337 27.8372 46.0889 27.487Z"
          fill="white"
        />
        <path
          d="M24.0481 19.8784C23.4177 19.2372 22.9867 18.6175 22.7658 18.0194C22.5395 17.4268 22.491 16.8879 22.6203 16.4138C22.7496 15.9396 23.0082 15.5624 23.3962 15.2823C23.4608 15.2445 23.5362 15.223 23.6171 15.2284C23.6979 15.2284 23.7733 15.2607 23.8272 15.3146L25.0502 16.5646C25.1418 16.6562 25.1849 16.7371 25.1903 16.8071C25.1956 16.8772 25.1903 16.958 25.1795 17.0496C25.1094 17.1735 25.0663 17.3136 25.0502 17.4806C25.034 17.6477 25.0717 17.8255 25.1525 18.0194C25.2334 18.2134 25.3788 18.4128 25.5782 18.6229C25.9499 19.0001 26.3001 19.2426 26.6395 19.3557C26.979 19.4689 27.2699 19.4473 27.5069 19.2857C27.6955 19.1779 27.7925 19.0378 27.7979 18.86C27.8033 18.6822 27.7386 18.4505 27.5985 18.1595C27.4585 17.8686 27.2537 17.5022 26.9736 17.0604C26.6126 16.4838 26.3755 15.9504 26.2624 15.4655C26.1493 14.9805 26.1762 14.5333 26.3486 14.1292C26.521 13.7251 26.8497 13.3695 27.3345 13.057C28.0619 12.599 28.8485 12.4589 29.6997 12.6313C30.551 12.8037 31.3807 13.3048 32.1888 14.1292C32.5983 14.5441 32.9 14.9751 33.1047 15.417C33.3095 15.8588 33.4226 16.2899 33.4442 16.6994C33.4657 17.1142 33.4172 17.4752 33.2933 17.7824C33.1694 18.0949 32.9916 18.332 32.7491 18.5044C32.6899 18.5475 32.6198 18.569 32.5336 18.5637C32.4474 18.5583 32.372 18.5259 32.3181 18.4721L31.0413 17.1627C30.9766 17.0981 30.9335 17.028 30.912 16.9526C30.8904 16.8772 30.8904 16.7963 30.9173 16.7047C31.0628 16.5646 31.1113 16.3653 31.0736 16.112C31.0359 15.8588 30.8958 15.6056 30.6533 15.3577C30.3786 15.0775 30.0984 14.8943 29.8129 14.8027C29.5273 14.7111 29.2579 14.7488 29.0047 14.9051C28.8431 15.0021 28.7461 15.1314 28.7138 15.293C28.6815 15.4547 28.7246 15.6702 28.8431 15.9342C28.9616 16.1983 29.1448 16.5377 29.4088 16.958C29.8506 17.6315 30.1415 18.2242 30.2708 18.7307C30.4055 19.2372 30.384 19.6844 30.2169 20.0723C30.0499 20.4603 29.7213 20.8105 29.2364 21.1176C28.439 21.6295 27.5985 21.775 26.715 21.5595C25.8314 21.3655 24.9424 20.7997 24.0481 19.8784Z"
          fill="white"
        />
        <path
          d="M30.6155 26.5919C30.2168 26.1824 29.8989 25.7514 29.6673 25.2934C29.4356 24.8354 29.3117 24.3774 29.2955 23.9194C29.2794 23.4614 29.3871 23.0142 29.6188 22.5885C29.8505 22.1629 30.2222 21.7695 30.734 21.4085C31.0627 21.1876 31.4021 20.9667 31.7469 20.7512C32.0917 20.5356 32.4311 20.3255 32.776 20.1154C33.8643 19.4688 34.9095 19.2048 35.9116 19.3179C36.9137 19.4311 37.8781 19.9645 38.8101 20.9128C39.2088 21.3223 39.5267 21.7588 39.753 22.2167C39.9793 22.6747 40.0978 23.1381 40.1086 23.6069C40.1194 24.0757 40.0062 24.5229 39.7745 24.9432C39.5429 25.3688 39.1657 25.7568 38.6485 26.1124C38.3253 26.3387 37.9912 26.5596 37.6464 26.7751C37.3016 26.9907 36.9622 27.2008 36.6174 27.4109C35.5129 28.0629 34.4623 28.3215 33.4764 28.1922C32.4958 28.0683 31.5368 27.5349 30.6155 26.5919ZM32.1564 25.3527C32.5012 25.7029 32.9053 25.9076 33.3686 25.9669C33.8319 26.0262 34.3492 25.8915 34.9149 25.552C35.2705 25.3419 35.6045 25.1425 35.9116 24.9485C36.2187 24.7546 36.5312 24.5498 36.849 24.3235C37.3878 23.9625 37.6787 23.6015 37.7272 23.2297C37.7757 22.8633 37.6302 22.5077 37.2854 22.1521C36.9406 21.7965 36.5366 21.6025 36.0678 21.5432C35.6045 21.484 35.0873 21.6187 34.5216 21.9581C34.1768 22.1521 33.8535 22.3461 33.5464 22.54C33.2393 22.734 32.916 22.9442 32.5766 23.1705C32.0432 23.5368 31.7523 23.9032 31.6984 24.2642C31.6445 24.6253 31.7954 24.9863 32.1564 25.3527Z"
          fill="white"
        />
        <path
          d="M37.4148 33.5329C37.0161 33.1234 36.6983 32.6923 36.4666 32.2343C36.2349 31.7763 36.111 31.3183 36.0948 30.8603C36.0787 30.4023 36.1864 29.9551 36.4181 29.5294C36.6498 29.1038 37.0215 28.7105 37.5333 28.3494C37.862 28.1285 38.2014 27.9076 38.5462 27.6921C38.891 27.4766 39.2359 27.2664 39.5753 27.0563C40.6636 26.4097 41.7088 26.1457 42.7109 26.2588C43.713 26.372 44.6774 26.9054 45.6095 27.8537C46.0082 28.2632 46.326 28.6997 46.5523 29.1577C46.7786 29.6157 46.8971 30.079 46.9079 30.5478C46.9187 31.0166 46.8055 31.4638 46.5739 31.8841C46.3422 32.3097 45.9651 32.6977 45.4478 33.0533C45.1246 33.2796 44.7905 33.5005 44.4457 33.716C44.1009 33.9316 43.7561 34.1417 43.4167 34.3519C42.3122 35.0038 41.2616 35.2624 40.2757 35.1331C39.2951 35.0146 38.3361 34.4758 37.4148 33.5329ZM38.9557 32.2936C39.3005 32.6438 39.7046 32.8486 40.1679 32.9078C40.6313 32.9671 41.1485 32.8324 41.7142 32.4929C42.0698 32.2828 42.4038 32.0834 42.7109 31.8895C43.018 31.6955 43.3305 31.4907 43.6484 31.2644C44.1871 30.9034 44.4781 30.5424 44.5265 30.1706C44.575 29.8042 44.4296 29.4486 44.0848 29.093C43.7399 28.7428 43.3359 28.538 42.8725 28.4788C42.4092 28.4195 41.892 28.5542 41.3263 28.8936C40.9815 29.0876 40.6582 29.2816 40.3511 29.4756C40.044 29.6695 39.7207 29.8797 39.3867 30.106C38.8533 30.4724 38.5624 30.8388 38.5085 31.1998C38.4439 31.5716 38.5947 31.9326 38.9557 32.2936Z"
          fill="white"
        />
        <path
          d="M41.6925 37.7086C41.6278 37.644 41.6009 37.5685 41.6117 37.4823C41.6225 37.3961 41.6656 37.3261 41.7464 37.2776L49.4023 32.4174C49.4885 32.3582 49.58 32.3366 49.677 32.3474C49.774 32.3582 49.8602 32.4013 49.9302 32.4713L50.9431 33.5059C51.0455 33.6082 51.094 33.7052 51.0832 33.786C51.0778 33.8722 51.0617 33.9315 51.0401 33.98L48.8204 39.2658L53.3083 36.4155C53.3945 36.3562 53.4861 36.3346 53.5885 36.3508C53.6908 36.367 53.777 36.4047 53.8363 36.4693L54.9839 37.6386C55.0539 37.7086 55.0862 37.7894 55.0755 37.8703C55.0701 37.9511 55.0216 38.0265 54.9408 38.0804L47.2849 42.9243C47.1987 42.9836 47.1071 43.0052 47.0047 42.9998C46.9024 42.9944 46.8162 42.9513 46.7461 42.8812L45.7333 41.8467C45.6309 41.7443 45.5878 41.6474 45.5986 41.5611C45.6093 41.4749 45.6201 41.4103 45.6363 41.3726L47.6998 36.1999L43.3788 38.9425C43.3034 38.991 43.2118 39.0125 43.1041 39.0018C42.9963 38.991 42.9101 38.9479 42.8401 38.8778L41.6925 37.7086Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_1144_25765"
          x1="46.6263"
          y1="57.1416"
          x2="63.0075"
          y2="57.1416"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.00649351" stopColor="#BA192D" />
          <stop offset={1} stopColor="#690E19" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1144_25765"
          x1="6.72674"
          y1="1.28726"
          x2="6.72674"
          y2="19.4275"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#690E19" />
          <stop offset="0.9935" stopColor="#BA192D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1144_25765"
          x1="23.969"
          y1="35.7227"
          x2="60.3944"
          y2="13.7491"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF223E" />
          <stop offset={1} stopColor="#B5182C" />
        </linearGradient>
        <clipPath id="clip0_1144_25765">
          <rect width={63} height={63} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ComingSoon;
