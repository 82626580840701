import React from 'react';

export default function BusinessIcon() {
  return (
    <svg width={13} height={12} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_567_818)">
        <path
          d="M12.6215 2.1329C12.6207 2.1329 12.6199 2.13281 12.6191 2.13281H9.16602V1.78125C9.16602 1.19971 8.65344 0.726562 8.02344 0.726562H4.97656C4.34656 0.726562 3.83398 1.19971 3.83398 1.78125V2.13281H0.380859C0.168808 2.13281 0 2.29321 0 2.48438V10.2188C0 10.8003 0.512573 11.2734 1.14258 11.2734H11.8574C12.4874 11.2734 13 10.8003 13 10.2188V2.49179C13 2.49133 13 2.49088 13 2.49042C12.9854 2.25952 12.8316 2.13419 12.6215 2.1329ZM4.5957 1.78125C4.5957 1.58743 4.76659 1.42969 4.97656 1.42969H8.02344C8.23341 1.42969 8.4043 1.58743 8.4043 1.78125V2.13281H4.5957V1.78125ZM12.0907 2.83594L10.908 6.11115C10.8561 6.25497 10.7109 6.35156 10.5467 6.35156H8.4043V6C8.4043 5.80582 8.2338 5.64844 8.02344 5.64844H4.97656C4.7662 5.64844 4.5957 5.80582 4.5957 6V6.35156H2.45327C2.28912 6.35156 2.14392 6.25497 2.09205 6.11115L0.909302 2.83594H12.0907ZM7.64258 6.35156V7.05469H5.35742V6.35156H7.64258ZM12.2383 10.2188C12.2383 10.4126 12.0674 10.5703 11.8574 10.5703H1.14258C0.93261 10.5703 0.761719 10.4126 0.761719 10.2188V4.65079L1.36941 6.33353C1.52512 6.76492 1.96073 7.05469 2.45327 7.05469H4.5957V7.40625C4.5957 7.60043 4.7662 7.75781 4.97656 7.75781H8.02344C8.2338 7.75781 8.4043 7.60043 8.4043 7.40625V7.05469H10.5467C11.0393 7.05469 11.4749 6.76492 11.6306 6.33353L12.2383 4.65079V10.2188Z"
          fill="#7F7F7F"
        />
      </g>
      <defs>
        <clipPath id="clip0_567_818">
          <rect width={13} height={12} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
