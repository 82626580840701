import React from 'react';

interface PropsType {
  active?: boolean;
}

const SlotsIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8335 8.25C19.7996 8.25 18.9585 9.09108 18.9585 10.125C18.9585 10.9388 19.4826 11.626 20.2085 11.8849V17H18.9585V19.5H21.4585V11.8849C22.1844 11.626 22.6668 10.9388 22.6668 10.125C22.6668 9.09108 21.8674 8.25 20.8335 8.25Z"
        fill={active ? '#FFE036' : '#7F7F7F'}
      />
      <path d="M1.3335 8.25H6.37516V13.25H1.3335V8.25Z" fill={active ? '#FFE036' : '#7F7F7F'} />
      <path d="M12.625 8.25H17.7083V13.25H12.625V8.25Z" fill={active ? '#FFE036' : '#7F7F7F'} />
      <path
        d="M9.50016 2C6.68095 2 4.47087 3.03821 3.97833 4.5H1.3335V7H17.7085V4.5H15.022C14.5295 3.03821 12.3194 2 9.50016 2Z"
        fill={active ? '#FFE036' : '#7F7F7F'}
      />
      <path d="M7.625 8.25H11.375V13.25H7.625V8.25Z" fill={active ? '#FFE036' : '#7F7F7F'} />
      <path
        d="M1.3335 22H17.7085V14.5H1.3335V22ZM3.87516 16.375H15.1252V20.125H3.87516V16.375Z"
        fill={active ? '#FFE036' : '#7F7F7F'}
      />
      <path d="M5.125 17.625H13.875V18.875H5.125V17.625Z" fill={active ? '#FFE036' : '#7F7F7F'} />
    </svg>
  );
};

export default SlotsIcon;
