/* eslint-disable react/prop-types */
import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import classnames from 'classnames/bind';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CONTRACT_ADDRESS from '../../contracts/address';
import { useErc20, useStakeBBet } from '../../hooks/useConstract';
import { RootState } from '../../stores';
import { updateLoadingStaking } from '../../stores/LoadingSlice';
import styles from '../../styles/Staking/claim-reward.module.scss';
import { Card, CardBody, CardHeader } from '../UI/Card';
import BigNumber from 'bignumber.js';
import { TOKENS } from '../../constant/token';
import { capitalizeFirstLetter } from '../../utils/capitalizeFirstLetter';
import { MAX_UINT_AMOUNT } from '../../lib/constants';

const cx = classnames.bind(styles);

export default function ClaimRewards() {
  const context = useWeb3React<Web3Provider>();
  const { account } = context;
  const [claimableAt, setClaimableAt] = useState(0);
  const [claimable, setClaimable] = useState('0');
  const [isApproved, setIsApproved] = useState(false);
  const dispatch = useDispatch();
  const loadingStaking = useSelector((state: RootState) => state.loading.loading.staking);
  const stakeBBetContract = useStakeBBet(CONTRACT_ADDRESS.STAKING);
  const stakeBBetContractWithSigner = useStakeBBet(CONTRACT_ADDRESS.STAKING, true);
  const busdContract = useErc20(CONTRACT_ADDRESS.BUSD);
  const busdContractWithSigner = useErc20(CONTRACT_ADDRESS.BUSD, true);

  const checkApprove = async () => {
    if (account) {
      try {
        const allowance = await busdContract.allowance(account, CONTRACT_ADDRESS.STAKING);
        if (allowance.gt(0)) {
          setIsApproved(true);
        } else {
          setIsApproved(false);
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const getData = async () => {
    if (account) {
      try {
        const [stakeInfo, disableClaimTime] = await Promise.all([
          stakeBBetContract.stakingInfo(account),
          stakeBBetContract.disableClaimTime()
        ]);
        const claimedAt = stakeInfo.claimedAt.toString();
        const stakingAt = stakeInfo.stakingAt.toString();
        let claimableAt = new BigNumber(claimedAt).gt(stakingAt) ? claimedAt : stakingAt;
        claimableAt = new BigNumber(claimableAt).plus(disableClaimTime.toString()).toFixed();
        setClaimableAt(new BigNumber(claimableAt).multipliedBy(1000).toNumber());
        setClaimable(new BigNumber(stakeInfo.claimableReward.toString()).div(1e18).toFormat(3));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleApprove = async () => {
    try {
      let tx = await busdContractWithSigner.approve(CONTRACT_ADDRESS.STAKING, MAX_UINT_AMOUNT);
      await toast.promise(tx.wait(), {
        pending: 'Process approve...',
        success: {
          render() {
            setIsApproved(true);
            return 'Approved';
          }
        },
        error: 'Approval failed'
      });
    } catch (error: any) {
      toast.error(capitalizeFirstLetter(error?.reason || error?.message));
    }
  };

  const handleClaim = async () => {
    try {
      const tx = await stakeBBetContractWithSigner.claimReward();
      dispatch(updateLoadingStaking(true));
      await toast.promise(tx.wait(), {
        pending: 'Process claim...',
        success: {
          render() {
            dispatch(updateLoadingStaking(false));
            return 'Claimed';
          }
        },
        error: {
          render() {
            dispatch(updateLoadingStaking(false));
            return 'Claim failed';
          }
        }
      });
    } catch (error: any) {
      toast.error(capitalizeFirstLetter(error?.reason || error?.message));
    }
  };

  const handleUnstake = async () => {
    try {
      const tx = await stakeBBetContractWithSigner.unstaking();
      dispatch(updateLoadingStaking(true));
      await toast.promise(tx.wait(), {
        pending: 'Process unstake...',
        success: {
          render() {
            dispatch(updateLoadingStaking(false));
            return 'Unstake successfully';
          }
        },
        error: {
          render() {
            dispatch(updateLoadingStaking(false));
            return 'Unstake failed';
          }
        }
      });
    } catch (error: any) {
      toast.error(capitalizeFirstLetter(error?.reason || error?.message));
    }
  };

  useEffect(() => {
    if (stakeBBetContract && !loadingStaking) {
      getData();
    }
  }, [account, stakeBBetContract, loadingStaking]);

  useEffect(() => {
    if (account) {
      checkApprove();
    }
  }, [account]);

  return (
    <Card>
      <CardHeader>
        <p className={cx('title')}>Claim My Rewards</p>
      </CardHeader>
      <CardBody>
        <div className={cx('body-container')}>
          <div className={cx('token-container')}>
            <img
              src={`/images/tokens/${TOKENS.BUSD.toLowerCase()}-large.svg`}
              alt="busd"
              className={cx('token-img')}
            />
            <p>
              <span className={cx('value-token')}>{claimable}</span> {TOKENS.BUSD}
            </p>
          </div>
          <div className={cx('content-container')}>
            <p className={cx('text-content')}>
              Start with ease, Claim your free Money here! Remaining days
            </p>
            {<Countdown destination={moment(claimableAt).format('YYYY-MM-DD HH:mm:ss')} />}
            <div className={cx('group-btn-action')}>
              {isApproved ? (
                <button
                  className={cx('btn-claim', 'btn')}
                  disabled={!account || loadingStaking}
                  onClick={handleClaim}>
                  Claim
                </button>
              ) : (
                <button
                  className={cx('btn-claim', 'btn')}
                  disabled={!account}
                  onClick={handleApprove}>
                  Approve
                </button>
              )}
              <button
                className={cx('btn-unstake', 'btn')}
                disabled={!account || loadingStaking}
                onClick={handleUnstake}>
                Unstake
              </button>
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

const Countdown = ({ destination = '' }) => {
  const [timeRemaining, setTimeRemaining] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00'
  });

  const parseTime = (time: number) => {
    if (time < 10) return `0${time}`;
    return `${time}`;
  };

  useEffect(() => {
    if (destination) {
      const countDown = setInterval(() => {
        const currentTime = moment().valueOf();
        const destinationTime = moment(destination).valueOf();
        let timeDifference = destinationTime - currentTime;
        if (timeDifference < 0) {
          clearInterval(countDown);
          timeDifference = 0;
        }
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60)) % 24;
        const minutes = Math.floor(timeDifference / (1000 * 60)) % 60;
        const seconds = Math.floor(timeDifference / 1000) % 60;
        setTimeRemaining({
          days: parseTime(days),
          hours: parseTime(hours),
          minutes: parseTime(minutes),
          seconds: parseTime(seconds)
        });
      }, 1000);
      return () => {
        clearInterval(countDown);
      };
    }
  }, [destination]);

  return (
    <div className={cx('count-down-container')}>
      <div>
        <div
          className={cx('time-block')}
          style={{ backgroundImage: `url(/images/staking/countdown-bg.png)` }}>
          {timeRemaining.days}
        </div>
        <p className={cx('text-time')}>Days</p>
      </div>
      <div className={cx('count-down-item-between')}>:</div>
      <div>
        <div
          className={cx('time-block')}
          style={{ backgroundImage: `url(/images/staking/countdown-bg.png)` }}>
          {timeRemaining.hours}
        </div>
        <p className={cx('text-time')}>Hours</p>
      </div>
      <div className={cx('count-down-item-between')}>:</div>
      <div>
        <div
          className={cx('time-block')}
          style={{ backgroundImage: `url(/images/staking/countdown-bg.png)` }}>
          {timeRemaining.minutes}
        </div>
        <p className={cx('text-time')}>Minutes</p>
      </div>
      <div className={cx('count-down-item-between')}>:</div>
      <div>
        <div
          className={cx('time-block')}
          style={{ backgroundImage: `url(/images/staking/countdown-bg.png)` }}>
          {timeRemaining.seconds}
        </div>
        <p className={cx('text-time')}>Seconds</p>
      </div>
    </div>
  );
};
