export default function MediumIcon() {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.64048 10.8558C8.66148 10.6395 8.58219 10.4255 8.42625 10.2795L6.83898 8.29631V8H11.7678L15.5771 16.667L18.9265 8H23.6248V8.29631L22.2675 9.64603C22.1504 9.73863 22.0926 9.8906 22.1168 10.0409V19.9591C22.0926 20.1094 22.1504 20.2614 22.2675 20.354L23.5927 21.7037V22H16.9265V21.7037L18.2996 20.3207C18.4345 20.1808 18.4345 20.1399 18.4345 19.9258V11.9097L14.6173 21.9679H14.1017L9.65648 11.9097V18.6507C9.6192 18.934 9.71003 19.2194 9.90273 19.4242L11.6885 21.671V21.9673H6.62476V21.671L8.4105 19.4242C8.60162 19.2188 8.68668 18.9318 8.64048 18.6507V10.8558Z"
        fill="white"
      />
    </svg>
  );
}
