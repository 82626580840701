import React from 'react';

const QuestionIcon = () => {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78757 12.1574C9.7857 12.1574 9.78382 12.1574 9.78195 12.1574C9.43695 12.1543 9.15945 11.8721 9.16257 11.5268L9.16538 11.2143C9.16538 11.1965 9.16632 11.1784 9.1682 11.1606C9.25476 10.2515 9.8582 9.69431 10.3435 9.2465C10.5079 9.09463 10.6635 8.95119 10.796 8.8015C10.9576 8.61931 11.1919 8.24619 10.9454 7.79588C10.6613 7.27588 9.96757 7.12869 9.42851 7.25213C8.86538 7.38088 8.65788 7.86181 8.58413 8.13681C8.49476 8.47025 8.15195 8.66806 7.81851 8.579C7.48507 8.48962 7.28726 8.14681 7.37632 7.81338C7.62538 6.88306 8.27163 6.23463 9.14913 6.03369C10.3301 5.764 11.5194 6.24119 12.0416 7.19588C12.4763 7.99088 12.3573 8.92369 11.7307 9.63056C11.5566 9.82681 11.3707 9.99869 11.1907 10.1646C10.7423 10.5787 10.4619 10.8556 10.4144 11.2552L10.4119 11.5374C10.4094 11.8815 10.1301 12.1574 9.78757 12.1574Z"
        fill="#7F7F7F"
      />
      <path
        d="M9.7876 14.0313C9.6251 14.0313 9.4626 13.9657 9.34697 13.85C9.22822 13.7344 9.1626 13.5719 9.1626 13.4063C9.1626 13.2438 9.22822 13.0813 9.34697 12.9657C9.57822 12.7344 9.99697 12.7344 10.2282 12.9657C10.347 13.0813 10.4126 13.2438 10.4126 13.4063C10.4126 13.5719 10.347 13.7313 10.2313 13.85C10.1126 13.9657 9.95322 14.0313 9.7876 14.0313Z"
        fill="#7F7F7F"
      />
      <path
        d="M9.97582 18.124C9.26488 18.124 8.56019 18.0315 7.88113 17.8487C4.95488 17.0615 2.66363 14.6375 2.04394 11.6728C1.41238 8.65248 2.57801 5.47748 5.01394 3.58404C6.42207 2.48936 8.198 1.88623 10.0149 1.88623C11.2727 1.88623 12.5218 2.17873 13.6274 2.73154C16.3577 4.09748 18.1218 6.95123 18.1218 10.0022C18.1218 12.4915 16.9471 14.8909 14.9796 16.4203C13.5664 17.519 11.7893 18.124 9.97582 18.124ZM10.0152 3.13623C8.47488 3.13623 6.97113 3.64561 5.78144 4.57092C3.71988 6.17342 2.73301 8.86061 3.26769 11.4169C3.79207 13.9247 5.7305 15.9756 8.20613 16.6415C8.77925 16.7956 9.37488 16.874 9.97582 16.874H9.97613C11.513 16.874 13.0174 16.3625 14.2127 15.4334C15.878 14.139 16.8721 12.1087 16.8721 10.0022C16.8721 7.42061 15.3793 5.00561 13.0686 3.84967C12.1355 3.38311 11.0799 3.13623 10.0152 3.13623Z"
        fill="#7F7F7F"
      />
    </svg>
  );
};

export default QuestionIcon;
