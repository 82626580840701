import { Web3Provider } from '@ethersproject/providers';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import classnames from 'classnames/bind';
import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TOKENS } from '../../constant/token';
import CONTRACT_ADDRESS from '../../contracts/address';
import { useStakeBBet } from '../../hooks/useConstract';
import { RootState } from '../../stores';
import styles from '../../styles/Staking/history.module.scss';
import { Card, CardBody, CardHeader } from '../UI/Card';
import ClaimHistory from './History/Claim';
import StakingHistory from './History/Staking';
import UnstakingHistory from './History/Unstaking';

const cx = classnames.bind(styles);

type Tab = 'staking' | 'claim' | 'unstaking';
interface TabObj {
  key: Tab;
  title: string;
  component: React.ReactNode;
}

export default function History() {
  const context = useWeb3React<Web3Provider>();
  const { account } = context;
  const stakeBBetContract = useStakeBBet(CONTRACT_ADDRESS.STAKING);
  const loadingStaking = useSelector((state: RootState) => state.loading.loading.staking);
  const [BBETStaked, setBBETStaked] = useState('0');
  const [XBBETEquivalent, setXBBETEquivalent] = useState('0');
  const [tabActive, setTabActive] = useState<Tab>('staking');

  const tabs = [
    { key: 'staking', title: 'Staking History', component: <StakingHistory /> },
    { key: 'claim', title: 'Claim History', component: <ClaimHistory /> },
    { key: 'unstaking', title: 'Unstaking History', component: <UnstakingHistory /> }
  ] as TabObj[];

  const getData = async () => {
    if (account) {
      try {
        const stakingInfo = await stakeBBetContract.stakingInfo(account);
        if (stakingInfo) {
          setBBETStaked(new BigNumber(stakingInfo.amount.toString()).div(1e18).toFormat(2));
          setXBBETEquivalent(new BigNumber(stakingInfo.amountX.toString()).div(1e18).toFormat(2));
        }
      } catch (error: any) {
        console.log(error);
      }
    }
  };

  const handleChangeTab = (tab: Tab) => {
    setTabActive(tab);
  };

  useEffect(() => {
    if (!loadingStaking) {
      getData();
    }
  }, [account, loadingStaking]);

  return (
    <Card>
      <CardHeader>
        <div className={cx('header-container')}>
          <p className={cx('title')}>My Staking:</p>
          <div className={cx('header-item')}>
            <p>
              {TOKENS.BBET} Staked: {BBETStaked}
            </p>
            <img src="/images/tokens/bbet.svg" alt="bbet" className={cx('token-img')} />
          </div>
          <div className={cx('header-item')}>
            <p>
              {TOKENS.XBT} Equivalent: {XBBETEquivalent}
            </p>
            <img src="/images/tokens/xbt.svg" alt="bbet" className={cx('token-img')} />
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className={cx('tabs-container')}>
          {tabs.map((tab) => {
            if (tab.key === tabActive) {
              return (
                <div className={cx('tab-item')} key={tab.key}>
                  <p className={cx('tab-active')}>{tab.title}</p>
                  <div className={cx('underline-tab')} />
                </div>
              );
            }
            return (
              <div
                className={cx('tab-item')}
                key={tab.key}
                onClick={() => {
                  handleChangeTab(tab.key);
                }}>
                <p>{tab.title}</p>
              </div>
            );
          })}
        </div>

        <div className={cx('table')}>{find(tabs, { key: tabActive })?.component}</div>
      </CardBody>
    </Card>
  );
}
