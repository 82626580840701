import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider
} from 'firebase/auth';
import { auth } from '..';

const provider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

provider.setCustomParameters({ prompt: 'select_account' });
facebookProvider.setCustomParameters({
  display: 'popup'
});

export const firebaseService = {
  signUp: (email: string, password: string): Promise<any> => {
    return createUserWithEmailAndPassword(auth, email, password).catch((error) =>
      Promise.reject(error)
    );
  },

  signIn: (email: string, password: string): Promise<any> => {
    return signInWithEmailAndPassword(auth, email, password).catch((error) =>
      Promise.reject(error)
    );
  },
  signInWithGoogle: (): Promise<any> => {
    return signInWithPopup(auth, provider).catch((error) => Promise.reject(error));
  },
  signInWithFacebook: (): Promise<any> => {
    return signInWithPopup(auth, facebookProvider).catch((error) => Promise.reject(error));
  },
  logout: (): any => {
    auth.signOut();
  }
};
