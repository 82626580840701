/* eslint-disable no-undef */

import { ethers } from 'ethers';
import UncheckedJsonRpcSigner from './signer';
import { simpleRpcProvider } from './providers';
import { Erc20__factory, Staking__factory } from '../contracts/types';
import getNodeUrl from './getRpcUrl';

const RPC_URL = getNodeUrl();

export const ETHER_PROVIDER = new ethers.providers.JsonRpcProvider(RPC_URL);

export function getProviderOrSigner(library: any, account: any) {
  return account ? new UncheckedJsonRpcSigner(library.getSigner(account)) : ETHER_PROVIDER;
}

export function isAddress(value: string) {
  try {
    return ethers.utils.getAddress(value.toLowerCase());
  } catch {
    return false;
  }
}

export function getErc20Contract(
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return Erc20__factory.connect(address, signerOrProvider);
}

export function getStakeContract(
  address: string,
  signer?: ethers.Signer | ethers.providers.Provider
) {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return Staking__factory.connect(address, signerOrProvider);
}
