import React from 'react';

interface PropsType {
  active?: boolean;
}

const LobbyIcon = ({ active }: PropsType) => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_566_827)">
        <path
          d="M12.5187 13.7286C12.2375 13.4211 11.7491 13.4238 11.4721 13.7387L7.47066 18.2856C7.23699 18.5511 7.23699 18.949 7.47066 19.2146L11.4721 23.7614C11.7483 24.0753 12.2363 24.0803 12.5187 23.7715L16.6783 19.2246C16.924 18.956 16.924 18.5441 16.6783 18.2755L12.5187 13.7286Z"
          fill={active ? '#FFE036' : '#808080'}
        />
        <path
          d="M9.89062 0C8.26148 0 7.07812 1.35975 7.07812 3.01491C7.07812 5.11373 9.01706 6.41001 11.537 8.61506C11.8021 8.84704 12.1979 8.847 12.463 8.61506C14.983 6.40992 16.9219 5.11327 16.9219 3.01495C16.9219 1.35966 15.7384 0 14.1094 0C13.2779 0 12.5523 0.365391 12 1.05937C11.4477 0.365391 10.7221 0 9.89062 0V0Z"
          fill={active ? '#FFE036' : '#808080'}
        />
        <path
          d="M7.03125 14.25C8.58206 14.25 9.84375 12.9883 9.84375 11.4375C9.84375 10.1288 8.9453 9.02602 7.73283 8.71359C7.78837 7.15139 6.52317 5.8125 4.92188 5.8125C3.32063 5.8125 2.05538 7.15139 2.11092 8.71359C0.898453 9.02602 0 10.1288 0 11.4375C0 12.9883 1.26169 14.25 2.8125 14.25C3.32456 14.25 3.80475 14.1119 4.21875 13.8718V15.6562H3.51562C3.12731 15.6562 2.8125 15.9711 2.8125 16.3594C2.8125 16.7477 3.12731 17.0625 3.51562 17.0625H6.32812C6.71644 17.0625 7.03125 16.7477 7.03125 16.3594C7.03125 15.9711 6.71644 15.6562 6.32812 15.6562H5.625V13.8718C6.039 14.1119 6.51919 14.25 7.03125 14.25Z"
          fill={active ? '#FFE036' : '#808080'}
        />
        <path
          d="M19.5412 5.81067C19.276 5.57873 18.8802 5.57873 18.6151 5.81067C16.0951 8.01581 14.1562 9.31247 14.1562 11.4108C14.1562 13.0661 15.3397 14.4257 16.9688 14.4257C17.4835 14.4257 17.9574 14.2854 18.375 14.0141V15.6562H17.6719C17.2836 15.6562 16.9688 15.971 16.9688 16.3593C16.9688 16.7476 17.2836 17.0625 17.6719 17.0625H20.4844C20.8727 17.0625 21.1875 16.7476 21.1875 16.3593C21.1875 15.971 20.8727 15.6562 20.4844 15.6562H19.7812V14.0141C20.1988 14.2854 20.6728 14.4257 21.1875 14.4257C22.8166 14.4257 24 13.066 24 11.4108C24 9.312 22.0611 8.01572 19.5412 5.81067Z"
          fill={active ? '#FFE036' : '#808080'}
        />
      </g>
      <defs>
        <clipPath id="clip0_566_827">
          <rect width={24} height={24} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LobbyIcon;
